.ipr-modal-container {
    .header-select {
        width: 154px !important;
        margin-bottom: 10px;
    }

    .detail-container {
        font-weight: 400;
        text-align: justify;
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid #cccc;
        padding: 5px;
        border-radius: 4px;
        width: 100%;
        margin-bottom: 7px;
    }

    .ipr-file-container {
        .ant-upload {
            display: none !important;
        }

        .ant-upload-list-picture-card {
            padding: 0px !important;
        }
    }

    .teethChart-container .teethRow-container .teethRow-one svg {
        width: 47px !important;
    }

    .aligner-component .ipr-detail-container .aligners-container .aligner-circle {
        width: 30px !important;
        height: 28px !important;
        line-height: 25px;
    }

    .teethChart-container .teethRow-container .teethRow-bgTwo {
        padding: 0px !important;
    }

    .teethChart-container .teethRow-container .teethRow-bgOne {
        padding: 0px !important;
    }

    // .aligner-component .ipr-detail-container .aligners-container .aligner-row {
    //   width: 92% !important;
    // }

    .aligner-component .ipr-detail-container {
        padding-top: 0px;
    }

    .aligner-0-upper {
        position: relative;

        &::before {
            left: 6px !important;
        }
    }

    .aligner-0-lower {
        position: relative;

        &::before {
            left: -2px !important;
        }
    }
}


.aligner-component {
    width: 100%;
    background: #fafafa;
    border: 1px dashed #d7d6d6;
    box-sizing: border-box !important;
    border-radius: 13px !important;
    overflow: auto;
    // max-height: 13rem !important;
    padding: 15px 10px;

    .heading-radio {
        display: flex !important;
        margin: 14px 0px !important;

        .ant-radio-button-wrapper {
            min-width: 150px !important;
            height: 40px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
        }

    }

    .attachmentInfo-container {
        padding-top: 10px;
        max-height: 430px;
        overflow-y: scroll;
        overflow-x: hidden;

        .toothName {
            border-radius: 6px;
            color: #FFF;
            font-size: 16px;
            font-weight: 700;
            width: 100%;
            text-align: center;
            padding: 5px;
            margin: 0;
        }

        .attachment-response {
            border-radius: 5px;
            background: #FFF;
            text-align: justify;
            padding: 10px 5px;
            color: #000;
            font-family: "Mulish", sans-serif;
            font-size: 15px;
            font-weight: 400;
            // min-height: 37.5px;
            word-break: break-all;
        }
    }


    .ipr-file-container {
        .ant-upload {
            display: none !important;
        }

        .ant-upload-list-picture-card {
            padding: 0px !important;
        }
    }

    .ant-collapse-header {
        padding: 10px 0px !important;
    }

    .ant-collapse-content-box {
        padding: 0px !important;
    }

    .ant-collapse-expand-icon {
        height: 40px !important;
    }



    .chart-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-form-item {
            margin-bottom: 0px !important;
        }

        h1 {
            margin: 0;
            color: #000;
            font-family: Mulish;
            font-size: 15px;
            font-weight: 700;
        }

        .header-select {
            width: 154px;
            text-align: left;
        }

    }

    .ipr-checks-scroll {
        height: 300px;
        overflow: scroll;
        margin-bottom: 1rem;

    }

    .aligner-scroll-container {
        height: 300px;
        overflow: scroll;
        padding-top: 25px;
    }

    .ipr-check-row {
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;

        // gap: 10px;
        .ant-checkbox-wrapper {
            //   align-self: flex-start !important;
            justify-self: center !important;
        }

        .patient-question {
            min-width: 250px;
            text-align: left;
        }

        .ipr-circle {
            align-self: center;
            // align-self: flex-end !important;
            // justify-self: flex-end !important;
            border-radius: 7px;
            // background: #000;
            width: 14px;
            height: 14px;
        }

    }

    .ipr-detail-container {
        background: #fff;
        padding-top: 15px;
        border-radius: 8px;

        .aligners-container {

            .aligner-rows {
                display: flex;
                align-items: center;

                .aligner-info {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 4px;
                    left: 0px;

                    h2 {
                        color: #000;
                        font-family: "Mulish", sans-serif;
                        font-size: 12px;
                        font-weight: 600;
                        margin: 0;
                    }

                    p {
                        color: #000;
                        font-family: "Mulish", sans-serif;
                        font-size: 10px;
                        font-weight: 600;
                        margin: 0;
                    }

                    .aligner-num {
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 26px;
                        width: 28px;
                        position: relative;
                        color: #fff;
                        z-index: 15;
                        font-weight: 700;
                    }
                }
            }

            .aligner-row {
                align-items: flex-end;
                display: grid;
                grid-template-columns: repeat(15, 1fr);
                column-gap: 1px;
                row-gap: 0px;
                position: relative;
                width: 92%;
                margin: 0px auto 0px auto;

            }

            .aligner-circle {
                height: 26px;
                width: 28px;
                line-height: 25px;
                border-radius: 50%;
                text-align: center;
                font-weight: 600;
                color: #fff;
                align-self: center;
                justify-self: center;
                margin-bottom: 10px;
                position: relative;

                // &::after {
                //   content: '';
                //   background: #777777;
                //   height: 20px;
                //   width: 1px;
                //   position: absolute;
                //   z-index: 12;
                //   left: 50%;
                // }
            }

            .aligner-rows {
                position: relative;
                cursor: pointer;
                width: 100%;
                border-radius: 8px;
                padding: 5px 0px;

                .del-btn {
                    position: absolute;
                    top: 5px;
                    right: -40px;
                    border: none;
                    box-shadow: none;
                    background: transparent;
                    z-index: 2;
                }
            }

            .selected-aligner {
                background: #e1f0f3;

            }
        }

        .aligner-0-upper {
            position: relative;

            &::before {
                content: '';
                background-image: url('../../../../assets/images/IprArrow.png');
                position: absolute;
                bottom: -29px;
                z-index: 12;
                width: 18px;
                height: 30px;
                background-size: contain;
                background-repeat: no-repeat;
                left: 25%;
            }
        }

        .aligner-0-lower {
            position: relative;

            &::before {
                content: '';
                background-image: url('../../../../assets/images/IprArrow.png');
                transform: rotate(180deg);
                position: absolute;
                top: -30px;
                z-index: 12;
                /* left: -6px; */
                width: 24px;
                height: 30px;
                background-size: contain;
                background-repeat: no-repeat;
                right: 30%;
            }
        }

        .aligner-upper {
            &::after {
                top: -20px;
            }
        }

        .aligner-lower {
            &::after {
                bottom: -20px;
            }
        }





        .ipr-row {
            .plus {
                width: 21px;

                svg {
                    width: 21px;
                }

                position: absolute;
                top: 40%;
                right: -15%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                color: #000;
                font-size: 12px;
                font-weight: 700;
                z-index: 1;
            }
        }
    }

    .treatment-collapse {
        background: #fff !important;
    }

}