@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

// @import "./assets/styles/antd.css";
@import "./assets/styles/globals.scss";

.App {
  text-align: center;
}
// .ant-input {
//   background-color: #f9f9f9 !important;
// }

// .ant-select-selector {
//   background-color: #f9f9f9 !important;
// }

.ant-spin-nested-loading {
  height: 100% !important;
}
.ant-spin-container {
  height: 100% !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//new styles
.NewClassAnchor {
  padding: 2px 2px 2px 10px;
  border-left: 2px solid #f0f0f0;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.scanName:hover {
  color: #27ddf5;
  cursor: pointer;
  transition: all 500ms ease;
}
.scanNameSmilelign:hover {
  color: rgb(222, 135, 84);
}

.titleIpr {
  display: flex;
  align-items: center;
}
.iprButton {
  border-radius: 30px;
  margin-left: 10px;
}
.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-text {
  margin-top: 0px;
}
h1.headerScans {
  text-align: left;
  font-weight: 600;
  font-size: 1em;
}
.clinicalPics {
  text-align: left;
}

.selected-treatment-tick {
  background-color: #e4f5eb;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: 10px;
  width: 40px;
  height: 40px;

  .anticon {
    font-size: 18px;
    color: green;
  }
}

.treatment-selectbox-multi {
  .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;
    .anticon {
      font-size: 18px;
      color: green;
    }
  }
}

//Remove space in required sign in ant forms
.ant-form {
  .ant-form-item-required {
    &::before {
      margin-inline-end: 0px !important;
    }
  }
}

.termsAndConsent {
  color: rgb(0, 198, 233);
}

.refinement-image-container {
  .ant-upload-list-picture-card-container {
    width: 200px;
    height: 200px;
  }
  .refinement-image-wrapper {
    position: relative;
    width: 200px;
    margin-right: 10px;
    .refinement-image-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.3s;
      .view-btn {
        margin-right: 15px;
        cursor: pointer;
      }
      .download-btn {
        cursor: pointer;
      }
    }
    &:hover {
      .refinement-image-mask {
        opacity: 1;
      }
    }
  }
  .ant-image {
    width: 200px;
    height: 200px;
    margin-right: 10px;
    border: 1px solid #d9d9d9;
    .ant-image-img {
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
  }
}

.ant-tooltip-inner {
  padding: 5px 20px !important;
}

//Treatment Progress
.treatment-progress-sub-cards {
  .ant-card-head {
    border-bottom: 1px dashed #f0f0f0;
    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
  .select-button {
    width: 4.1rem;
    font-family: Mulish;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
  }
  &:hover {
    .select-button {
      visibility: visible;
    }
  }
}

.treatment-progress-sub-cards-selected {
  border: 2px solid #57c4e5 !important;
  &.treatment-progress-sub-cards-smilealign {
    border: 2px solid #de8754 !important;
  }
  .select-button {
    display: none;
  }
}

.ipr-session-card {
  border-radius: 4px;
  border: 1.5px solid #ececec;
  // background: #EBF9FF;
  background: #f9f9f9;
  text-align: left;
  .ant-card-body {
    padding: 20px 15px 20px 20px;
  }

  h1 {
    color: #343434;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
  }

  h2 {
    color: #343434;
    font-family: "Mulish", sans-serif;
    font-size: 14px;
    font-weight: 700;
  }

  .session-icon {
    display: flex;
    justify-content: center;
    margin-left: auto;
    width: 90%;
  }

  .status {
    display: flex;
    align-items: center;
    margin-top: 20px;
    span {
      color: #343434;
      font-family: "Mulish", sans-serif;
      font-size: 14px;
      font-weight: 700;
      padding-left: 10px;
    }
  }
}

.smileAlign-app {
  font-family: "Roboto", sans-serif !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  .subheading,
  .caption,
  .ant-card-head-title,
  .content,
  span,
  a,
  td,
  th,
  .ant-typography,
  .ant-steps-item-title,
  .treatment-label,
  .treatment-info,
  div,
  .patientListLength,
  .register-patient-status {
    font-family: "Roboto", sans-serif !important;
  }
}

.register-patient-status {
  padding: 7px 12px;
  border-radius: 15px;
  display: inline;
}

.notifications-modal-container {
  margin-bottom: 2rem;
  .header-row {
    p {
      color: #9ca5bc;
      font-family: "Mulish", sans-serif;
      font-size: 16px;
      font-weight: 600;
      margin: 0px;
    }
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .form-label {
    color: #3c4643;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  .notification-columnTwo {
    // text-align: right;
    padding-right: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .notification-select {
    justify-content: space-between;
    .duration-span {
      position: absolute;
      top: -25px;
    }
  }
  .onLabel {
    color: #000;
    font-family: "Mulish", sans-serif;
    // font-size: 18px;
    font-weight: 600;
    margin-left: 15px;
  }
}

.notificationShareModal {
  .users-shareList-container {
    .user-listItem {
      .del-btn {
        border: none !important;
        outline: none !important;
      }
      p {
        margin: 0px;
      }
    }
  }
}

.okButtonClass {
  width: 7rem;
  border-radius: 32px;
}

.refinement-detail-modal {
  label {
    color: #9ca5bc;
    font-size: 14px;
  }
  .detail {
    color: #404040;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
  .images-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.app-button-class {
  min-width: 7rem;
  border-radius: 30px;
}

.partnersMenu {
  .ant-dropdown-menu-title-content {
    font-weight: 600 !important;
  }
}


.whitelabel-tooltip{
  &.anticon{
    margin-left: 5px;
    cursor: pointer;
  }
}

.emoji-font{
  font-family: "Noto Color Emoji", sans-serif;
}

.country-dropdown{
  width: 300px !important;
}

.country-select{
  .ant-select-selector{
  .country-name{
      display: none;
  }
  }
}

.sectionInputTypeAddPatient {
  display: flex;
  justify-content: space-between;
}

.inputTypeClassAddPatient {
  width: 300px;
}



.order-uploader {
  .ant-upload-list-item {
    height: 120px !important;
  }
  .ant-upload {
    position: relative;
    height: 102px;
    text-align: center;
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    width: 155px !important;
    flex-direction: column;
    min-height: 155px;
  }



  .scan-upload-text {
    color: rgba(34, 48, 50, 0.72) !important;
    font-size: 12px !important;
    padding: 0 6px;
    font-weight: 600;
    margin: 10px 0px 0px 0px;
  }

  .ant-upload-list-picture-card {
    display: flex;
    flex-wrap: nowrap !important;
  }

}

.order-invoice-modal{
  h1{
    text-align: center;
  }
  .download-allBtn{
    display: flex;
    align-items: center;
    gap:10px;
    justify-content: flex-end;
    color: #000;
    font-weight: 600;
    cursor: pointer;
  }
  .carousel-container{
    .arrow-icon{
     cursor: pointer;
    }
    .resource-container{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .resource{
      position: relative;
      margin: 40px auto;
      display: flex;
      align-items: center;
      flex-direction: column;
  
      .resource-wrapper{
      cursor: pointer;
       visibility: hidden;
       position: absolute;
       width:131px;
       height: 131px;
       background-color: rgba(0, 0, 0, 0.35);
       display: flex;
       align-items: center;
       justify-content: center;
       border-radius: 8px;
       svg{
        color: #fff;
       }
      }

    }
    .resource:hover{
      .resource-wrapper{
        visibility: visible;
        }
    }
  }
  .resources-container{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    a {
        max-width: 200px;
    }

  }

}

.invoice-viewBtn{
  // margin-right:10px;
  border:none;
  background: transparent;
  box-shadow: none;
  padding: 0px;
  height: auto;
}

.treatment-arches-dp{
  display: flex;
  justify-content: space-between;
  .dp-label{
    font-weight: 600;
  }
  .db-arches{
    color: #879598 !important;
  }
}

.hyperlinksChat{
  word-break: break-all;
}


.conflict-menu{
  .ant-dropdown-menu-title-content{
    display: block !important;
  }
}

.mobile-view-container {
  text-align: center;
  padding: 0 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  p{
    font-weight: bold;
  }
}

.stroke-teeth{
  svg{
       stroke: #00C6E9;
      stroke-width: 3px;
  }
}