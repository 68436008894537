.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  border-radius: 6px 6px 0 0;
}
.header-container__title {
  margin: 0;
}
