.treatments-container {
    margin: 20px 20px;

    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
        margin-bottom: 1rem;
    }
}

.treatment-cards-container {
    margin: 30px 0px;
    padding-bottom: 30px;
    transition: all 0.3s ease;
  
    .treatment-card {
      transition: all 0.3s ease;
      border: 1px solid #b1b1b1;
      border-radius: 14.9px;
      padding: 5px;
      min-height: 200px;
      position: relative;
  
      .treatment-img {
        svg {
          margin: 50px auto;
          display: block;
          width: 95px;
          height: 69px;
        }
      }
  
      .treatment-name {
        font-family: "Mulish", sans-serif;
        font-size: 14.91px;
        font-weight: 600;
        text-align: left;
        background: #ebebeb;
        padding: 3px 8px;
        border-radius: 6.63px;
        margin-bottom: 0px;
      }
  
      .treatment-btn {
        width: 100%;
        flex-direction: row-reverse;
        opacity: 0;
        visibility: hidden;
        transition: all 1s ease,
         visibility 0.3s ease;
        margin-top: 10px;
        position: absolute;
      }
  
      &:hover {
        .treatment-btn {
          opacity: 1;
          visibility: visible;
          position: relative;
        }
      }
    }
  }
  