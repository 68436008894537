.prescriptionCard {

    .ant-card-head {
        background: #fff !important;
        border-radius: 8px;
    }

    .ant-card-body {
        padding: 24px 0px !important;
    }

    .draft-btn {
        color: #F00;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FF00002B;
        border: none;
        margin-left: 10px;

        &:hover {
            color: #F00 !important;
        }
    }
}

.prescription-form-container {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    // margin-top: 2rem;
    label {
        color: #000;
    }

    .ant-form-item-explain-error {
        text-align: left !important;
    }

    .form-heading {
        color: #000;
        text-align: left;
        font-size: 15px;
        font-family: "Mulish", sans-serif;
        font-weight: 700;
    }

    .prescription-form {
        .num-btn {
            border-radius: 50%;
            text-align: center;
            background: #00C6E9;
            position: absolute;
            left: -30px;
            top: 43px;
            color: #fff;
            padding: 0px 7px;
        }

        .delete-btn {
            border: none !important;
            box-shadow: none !important;
        }

        .form-select {
            // height: 56px !important;
            text-align: left;
            width: 100%;
        }

        .ant-divider {
            margin: 10px 0 !important;
        }

        .formRow {
            display: grid;
            grid-template-columns: 4fr 5fr;
            gap: 15px;
            margin: 0 10px;
        }
        .ant-select-open {
            .ant-select-arrow {
                transform: rotate(180deg) !important;
                transition: all 0.3s !important;
            }
        }

        .addOn-btn {
            display: flex;
            align-items: center;
            border: none;
            color: #828282;
            font-weight: 400;
            box-shadow: none;
            margin-right: auto;
            padding: 0px;

            svg {
                margin-right: 8px;
            }
        }

        .treatment-row {
            .ant-select-open {
                .ant-select-arrow {
                    transform: rotate(0deg) !important;
                    transition: all 0.3s !important;
                }
            }
            .ant-form-item {
                margin-bottom: 37px !important;
            }

            .ant-col {
                text-align: left;
            }
        }

        .aligner-row {
            padding-left: 15px;

            .ant-form-item {
                margin-bottom: 14px !important;
            }

            .del-btn {
                svg {
                    width: 15px;
                }
            }
        }

        .instruction-row {
            padding-left: 15px;
            text-align: left;

            .ant-form-item {
                margin-bottom: 29px !important;
            }

            .ant-radio-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr 1fr !important;
                // gap: 5px;
            }
        }

        .bite-row {
            padding-left: 15px;
            text-align: left;
            margin: 15px 0px;

            // .ant-radio-group{
            //     display: flex !important;
            // }
            .ant-row {}

            .ant-radio-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr 1fr !important;
                gap: 5px;
            
            }

            .ant-radio-wrapper {
                align-items: center !important;
            }

            .bite-select {
                position: relative;
                display: flex;

                .ant-form-item {
                    margin-bottom: 0px !important;
                }

                .ant-select {

                    //  width: 100% !important;
                    //  min-width: 110px !important;
                    &::before {
                        content: "As needed";
                        position: absolute;
                        top: -20px;
                        color: #000;
                        font-family: Mulish;
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
            }
        }

        .ipr-row {
            padding-left: 15px;
            text-align: left;
        }

        .incisal-row {
            padding-left: 15px;
            text-align: left;
            // .ant-radio-group {
            //     display: flex !important;
            //     justify-content: flex-start !important;
            // }
        }

        .spacing-row {
            padding-left: 15px;
            text-align: left;

            .ant-radio-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr !important;
                gap: 5px;
                row-gap: 10px;
            }
        }

        .bio-row {
            .ant-radio-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr 1fr !important;
                gap: 5px;
            }
        }

        .skeletal-row {
            padding: 0px 20px 0px 20px;
            text-align: left;
            .ant-form-item {
                margin-bottom: 0px !important;
            }
        }
    }

    .formFooter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 26px;
        // position: fixed;
        // bottom: 0px;
        width: 100%;
        z-index: 2;

        .formRowOne {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                color: #000;
            }

            a {
                color: #00C6E9;
                text-decoration: underline;
            }
        }

        .ant-form-item {
            margin-bottom: 0px !important;
        }

        .buttons-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 30px;
            .cancel-btn {
                border-radius: 27px;
                border: 1px solid #BCBCBC;
                color: #000;
                font-size: 16px;
                font-weight: 500;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            .submit-btn {
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                border-radius: 27px;
                background: #00C6E9;
                color: #FFF;
                margin-left: 10px;
            }
        }
    }

}

.addPatientModal {
    .ant-modal-body {
        // background: #F3F6FA;
    }
}


.prescription-modal-container {
    .scan-files-container {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        // box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px !important;
        margin: 0px 10px 25px 10px;

        .ant-divider {
            margin: 10px 0px !important;
        }

        .scanFiles-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 14px 0px 20px;


            .ant-select {
                width: 178px;
                text-align: left;
            }

            label {
                color: #77888B;
                font-size: 14px;
                font-weight: 400;
                padding-right: 20px;

                span {
                    color: #F00;
                }
            }

            .scan-form-heading {
                padding: 0px;

            }
        }

        .scan-form-container {
            width: 50%;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 4fr 0fr;
            column-gap: 0px;


            .scan-subheader {
                text-align: start;
                color: gray;
                // padding-left: 10px;
                margin-bottom: 5px;
            }

            .required {
                &:before {
                    display: inline-block;
                    margin-right: 4px;
                    color: #ff4d4f;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                    line-height: 1;
                    content: "*";
                }
            }

            .scanFiles-col {
                background-color: #ffffff;
                border-radius: 8px;

                .ant-divider {
                    margin: 10px 0 !important;
                }


            }

            .clinicFiles-col {
                background-color: #ffffff;
                border-radius: 8px;

                .ant-divider {
                    margin: 10px 0 !important;
                }

                .clinic-file-upload {
                    .ant-upload {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        background: rgba(0, 0, 0, 0.02);
                        border: 1px dashed #d9d9d9;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: border-color 0.3s;
                        min-height: 130px !important;
                        width: 170px;
                    }

                    .scan-upload-text {
                        color: rgba(34, 48, 50, 0.72) !important;
                        text-align: center;
                        font-family: "Mulish", sans-serif;
                        font-size: 12px !important;
                        padding: 0 6px;
                        font-weight: 600;
                        line-height: normal
                    }
                }
            }

            .ant-card-body {
                display: flex;
                flex-direction: row;
            }

            .scan-uploader {
                border-radius: 10px !important;
                height: 100% !important;
                width: 100% !important;
                //  height: 12rem !important;
                //  width: 15rem !important;
                //  height: 4rem !important;
                // width: 18rem !important;
                // display: inline-block !important;
                .ant-upload {
                    // border-radius: 6px !important;
                    // border: 1px solid #E1E1E1 !important;
                    // background: #FAFAFA !important;
                    //   padding: 16px 0 !important;
                    width: 100% !important;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.02);
                    border: 1px dashed #d9d9d9;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: border-color 0.3s;
                }

                .ant-upload-btn {
                    display: table;
                    width: 100%;
                    height: 100%;
                    outline: none;
                }
                .scan-upload-text {
                    color: rgba(34, 48, 50, 0.72) !important;
                    text-align: center;
                    font-family: Mulish;
                    font-size: 12px !important;
                    padding: 0 6px;
                    font-weight: 600;
                    line-height: normal;
                }

            }
        }
    }


    .chart-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px !important;

        .ant-form-item {
            margin-bottom: 0px !important;
        }

        h1 {
            margin: 0;
            color: #000;
            font-family: "Mulish", sans-serif;
            font-size: 15px;
            font-weight: 700;
        }

        .header-select {
            width: 154px;
            text-align: left;
        }

    }

    .ant-collapse-arrow {
        top: -72px !important;
    }

    .prescription-form-container {
        margin-top: 0px;
    }



    .teethChart-container {
        padding: 0px !important;

        .teethRow-bgOne {
            padding: 0px !important;
        }

        .teethRow-bgTwo {
            padding: 0px !important;
        }

        .teethChart-container .ant-collapse-expand-icon {
            margin-top: 4px !important;
            padding-right: 6px !important;
        }

        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 42px;
                }
            }
        }
    }
}