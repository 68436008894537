.order-header__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.order-header__first-col {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.order-header__second-col {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: baseline;
}
