@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.patient-detail-container {
  width: 1250px;
  height: 360px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.patient-clinic-detail-bottomRow {
  display: flex;
  align-items: flex-start;
  margin-left: 25px;
}

.patient-detail-container-patientInfo {
  width: 100%;
  // height: 220px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.patient-detail-container-clinicInfo {
  width: 100%;
  // height: 220px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.patient-detail-topRow {
  border-bottom: 1px solid #e9e9e9;
  min-height: 85px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.patient-detail-topRow-profile {
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  margin-top: 12px;
}
.hoverIcon {
  visibility: hidden;
}


.patient-edit {
  width: 14px;
  &:hover {
    cursor: pointer;
  }
}

.patient-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
  position: relative;
}
.sub-highlight{
  // cursor: pointer;
  &:hover {
    color: #05a2bd;
  }
}
.subheading {
  font-family: "Poppins", sans-serif !important;
  font-weight: "400";
  font-size: 20px;
}
.subheading-smilealign {
  &:hover {
    color: #de8754;
  }
}

.profile-icon {
  border: 2px solid #e9e9e9;
  height: 60px;
  width: 60px;
  border-radius: 35px;
}
.user-icon-image {
  height: 30px;
  width: 30px;
  margin-top: 12px;
  margin-left: 1px;
}
.patient-detail-topRow-switcher {
  padding-top: 25px;
  padding-right: 16px;
}
.ant-radio-button-wrapper:first-child {
  border-radius: 12px 0 0 12px !important;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 12px 12px 0 !important;
}
.patient-detail-bottomRow {
  // display: flex;
  // justify-content: space-around;
  &.patient-detail-middleRow {
    margin-top: 30px;
  }
}

.patient-detail-bottomRow-itemOne {
  background-color: #fafafa;
  width: 780px;
  height: 233px;
  margin-bottom: 20px;
  border-radius: 13px;
  border: 2px dashed #e9e9e9;
}

.patient-detail-bottomRow-itemTwo {
  background-color: #fafafa;
  width: 400px;
  height: 233px;
  margin-bottom: 20px;
  border-radius: 13px;
  border: 2px dashed #e9e9e9;
  margin-left: -25px;
}
.status-container {
  height: 80%;
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}
.ant-dropdown-link {
  color: #222222;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
}
.patient-info {
  background-color: #fafafa;
  width: 98%;
  min-height: 70px;
  margin-bottom: 32px;
  border-radius: 13px;
  border: 2px dashed #e9e9e9;
  text-align: left;
  padding: 10px;
  margin: auto;
  margin-bottom: 20px;
.border-right{
  border-right: 2px solid rgb(233, 233, 233);

}
  // display: flex;
}

.hidden {
  display: none;
}

.caption {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #798c96;
  padding: 10px 0px;
}

.content {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: #222222;
  text-transform: capitalize;
  display: flex;
}

.gender{
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
  width: 170px;
  text-align: left;
  span{
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    color: #222222;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px;
    width: 170px;
    text-transform: initial;
  }
}
.email-content {
  margin-left: 8px;
  width: 250px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: auto;
  display: -webkit-box;
  text-align: left;
  word-wrap: break-word;
  white-space: break-spaces;
  max-height: 41px;
  line-height: 18px;
  text-transform:none;
}

.page-header-patient-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .sla-tag {
    // margin-top: 20px;
    margin-left: 10px;
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.order-status {
  color: #05a2bd;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 13px;
  background: #ddfaff;
  padding: 6px;
  border-radius: 15px;
  width: auto;
  margin-top: 20px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
}
.site-page-header {
  text-align: left;
  padding-inline-end:0 ;
}

.aligner {
  margin-top: 1.4rem;
  margin-right: 1rem;
}
.detail-radio-buttons {
  width: auto;
  text-align: center;
  margin-top: 20px;
  margin-right: 20px;
  height: 35px;
}

.commentBtn {
  float: right;
  position: fixed !important;
  right: 5rem;
  bottom: 23px;
  z-index: 10;
  padding: 0;
  svg{
    width: 100%;
    height: 100%;
  }
}
.site-breadcrumbs{
  padding-left: 16px;
}

// .chat-intercom-Btn {
//   float: right;
//   position: fixed !important;
//   right: 1rem;
//   bottom: 23px;
//   z-index: 10;
//   padding: 0;
//   z-index: 10000;
//   svg{
//     width: 100%;
//     height: 100%;
//   }
// }

// #intercom-container-body{
//   display: none !important;
//   z-index: 10 !important;
// }

// .intercom-lightweight-app-launcher , .intercom-launcher{
//   display: none !important;
//   z-index: 10 !important;
// }