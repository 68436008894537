.scan-file-wrapper {
    width: 100px;
    height: 96px;
    border: 1px solid #ddd;
    overflow: hidden;
    border-radius: 9px;
    background-color:#F5F5F5;
    transition: box-shadow 0.3s;
    box-sizing: border-box;

    &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

        .fileMask {
            opacity: 1;
        }
    }
}

.scan-file-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    padding: 0 10px;

    .scan-file-icon {
        font-size: 40px;
        transition: transform 0.3s;
        flex: 0 0 75%;
    }

    .fileName {
        flex: 0 0 25%;
        text-align: center;
        font-size: 11px;
        color: #333;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
        width: 100%;
    }

    .fileMask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.3s;

        .anticon {
            color: #fff;
            margin: 0 8px;
            cursor: pointer;
            transition: transform 0.2s;

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

.uploader-container {
    padding: 7px;
    width: 92px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    font-size: 10px;
    border-radius: 9px;
    border: 1px solid #C3C3C3;
    background: #FAFAFA;

    &:hover {
        cursor: pointer;
    }
}


.bulk-upload-container {
    margin-bottom: 20px;
    :global {
        .ant-upload {
            background: transparent !important;
        }
        .ant-upload-wrapper .ant-upload-drag {
            border-radius: 10px !important;
        }
        .ant-upload-text {
            font-weight: 400 !important;
            font-size: 13px !important;
            color: #000 !important;
        }
        .ant-upload-hint {
            font-weight: 700 !important;
            font-size: 13px !important;
            color: #000 !important;
        }

    }
}
