.upload-container {
  // display: flex;
  // gap: 2rem;
  // flex-direction: row;
}

.upload_child {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload-dragger__container {
    width: 100%;
    height: 108px;
  }
