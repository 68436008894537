.products-view-container {
    margin: 20px 20px;
    text-align: left;
    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }
}