.send-to-patient-table-bottom {
  width: 100%;
  // margin-top: 20px;

  .ant-table-thead {

    .ant-table-cell {
      color: #798c96 !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      font-family: "Mulish", sans-serif !important;
      // border-bottom: none !important;
      padding: 5px 0px !important;

      &::before {
        background-color: transparent !important;
      }

    }
  }

  .ant-table-tbody {
    background: #fafafa;

    .ant-table-cell {
      border-bottom: 1px solid #ECECEC !important;
      // padding-top: 0 !important;
      padding: 5px 0px !important;
      color: rgba(34, 34, 34, 0.72);
      font-weight: 800;
      font-size: 14px;
      font-family: "Mulish", sans-serif;
    }
  }

  .ant-table-summary {
    background: #fafafa;

    .ant-table-cell {
      border-bottom: 1px solid #ECECEC !important;
      color: rgba(34, 34, 34, 0.72);
      font-weight: 800;
      font-size: 14px;
      font-family: "Mulish", sans-serif;
      padding: 5px 0px !important;
    }
  }

  p {
    margin: 0px;
  }
}



.product-toolTip {
  p {
    margin: 5px 0px;
    text-align: left;
  }

  .border-line {
    background: #fafafa;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
    margin-top: 10px;
  }

}