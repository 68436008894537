.patient-treatment-details-container {
  width: "100%";
  min-height: "50rem";
  box-shadow: "0px 10px 16px rgba(0, 0, 0, 0.02)";
  border-radius: "8px";
  background-color: "white";
  padding-bottom: "1rem";
  height: 100%;
}
.ant-collapse-header {
  text-align: left;
  font-weight: 600;
  font-family: Mulish;
  font-size: 16px;
}
