.patient-treatment-details-container {
  width: 100%;
  min-height: 50rem;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  background-color: white;
  padding-bottom: 6px;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.02);
  min-height: 54.2rem;
  // max-height: 54.2rem;
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-expand-icon {
    height: 40px !important;
  }
  .chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px !important;

    .ant-form-item {
      margin-bottom: 0px !important;
    }

    h1 {
      margin: 0;
      color: #000;
      font-family: "Mulish", sans-serif;
      font-size: 15px;
      font-weight: 700;
    }

    .header-select {
      width: 154px;
      text-align: left;
    }
  }

  .ipr-file-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .ant-upload {
      display: none !important;
    }
    .ant-upload-wrapper {
      width: auto !important;
    }

    .ant-upload-list-picture-card {
      padding: 0px !important;
    }
  }
  .patient-treatment-details {
    border-bottom: 1px solid lightgray;
    flex-direction: row;
    align-items: baseline;
    text-align: left;
    padding: 0.8rem 1rem 0.5rem 1rem;
    min-height: 110px;
  }
  .aligner-component-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .aligner-component {
    width: 99%;
    background: #fafafa !important;
    border: 1px dashed #d7d6d6 !important;
    box-sizing: border-box !important;
    border-radius: 13px !important;
    overflow: auto;
    // max-height: 13rem !important;
    padding: 1rem !important;
    margin: 5px;
    margin-bottom: 10px;
    .heading-radio{
      display: flex !important;
      margin: 14px 0px !important;
      .ant-radio-button-wrapper{
        min-width: 120px !important;
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
    }



    .patient-response{
    font-weight: 800;
    font-size: 14px;
    color: #000;
    text-align: left;
    margin: 10px 0px !important;
    }

    .detail-container{
      font-weight: 400;
      text-align: justify;
      max-height: 200px;
      overflow-y: auto;
      border: 1px solid #cccc;
      padding: 5px;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 7px;
    }
    .attachmentInfo-container {
      padding-top: 10px;
      max-height: 430px;
      overflow-y: scroll;
      overflow-x: hidden;
  
      .toothName {
        border-radius: 6px;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        text-align: center;
        padding: 5px;
        margin: 0;
      }
  
      .attachment-response {
        border-radius: 5px;
        background: #FFF;
        text-align: justify;
        padding: 10px 5px;
        color: #000;
        font-family: "Mulish", sans-serif;
        font-size: 15px;
        font-weight: 400;
        word-break: break-all;
      }
    }

    .download-btn{
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-left: 10px;
      cursor: pointer;
      svg{
       margin-left: 4px;
      }
    }
  }
  .iframe-container {
    margin-top: 0.3rem;
    padding: 0 0.5rem;
    width: 100%;
    min-height: 35rem;
    text-align: left;
    position: relative;
    display: flex;
    justify-content: center;
    

    .down-btn {
      margin: 8px 0px 8px auto;
      display: flex;
    }

    .treatment-download-btn{
      position: relative;
      right: 65px;
    }

    .not-supported {
      padding: 7px;
      box-shadow: #3c40434d 0px 1px 2px 0px, #3c404326 0px 1px 3px 1px;
      border-radius: 5px;
      min-height: 35rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .fileImage {
        position: relative;

        .fileMask {
          position: absolute;
          inset: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          background: rgba(0, 0, 0, 0.5);
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.3s;
          width: 131px;
          height: 131px;
          border-radius: 8px;

          span {
            padding-left: 3px;
          }
        }

        &:hover {
          .fileMask {
            opacity: 1;
          }
        }
      }

      .resourceName {
        p {
          color: #404040;
          width: 131px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }

    video {
      width: 100%;
      height: auto;
      border-radius: 5px;

    }
  }
    .iframe-wrapper {
      position: relative;
      max-width: 710px;
      display: block;
      width: 100%;
  
      .editbutton-wrapper {
        position: absolute;
        right: 30px;
        top: 10px;
  
        &.editbutton-wrapper-file {
          top: -8px;
        }
      }
  
      .viewbutton-wrapper {
        position: absolute;
        right: 75px;
        top: 10px;
      }
  
      .iframe-image {
        width: 100%;
        min-height: 43rem;
        padding: 7px;
        box-shadow: #3c40434d 0px 1px 2px 0px, #3c404326 0px 1px 3px 1px;
        // border: 0.5px solid #00c6ec78;
        border-radius: 5px;
        max-width: 710px;
        display: block;
        margin: 0 auto;
      }
    }

  .detail-container {
    padding: 0px 5px;
    align-items: center;
    margin: 14px 0px;
  }
  .text-container {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    font-weight: 520;
    font-size: 1rem;
  }
  .button-padding {
    border-radius: 50px;
  }
  .image-container {
    width: 35px;
    cursor: pointer;
  }
  .confirm-treatment-container {
    display: flex;
    margin-left: auto;
    padding-right: 10px;
    .confirm-treatment-button {
      border-radius: 5rem;
      font-family: "Mulish";
    }
  }
  .status-massage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .status-massage-cancel {
      background: rgba(254, 80, 80, 0.18);
      border-radius: 34px;
      color: red;
      padding: 0.4rem;
      width: 7rem;
      text-align: center;
      margin-top: 0.5rem;
    }
    .status-massage-approved {
      background: #e4f5eb;
      border-radius: 34px;
      color: green;
      padding: 0.4rem;
      padding-top: 11px;
      width: 7rem;
      text-align: center;
      // margin-top: 0.5rem;
      height: 2.5rem;
      margin-right: 5px;
      margin-bottom: 0;
    }
  }
  .status-massage-modify {
    background: rgba(254, 80, 80, 0.18);
    border-radius: 34px;
    color: red;
    padding-top: 0.5rem;
    width: 11rem;
    text-align: center;
    height: 2.5rem;
    margin-right: 5px;
    margin-bottom: 0;
  }
  .status-massage-submitted {
    background: rgb(224, 224, 224);
    border-radius: 34px;
    color: rgb(20, 20, 20);
    // padding-top: 0.5rem;
    width: 7rem;
    text-align: center;
    height: 2.5rem;
    margin-left: auto;
    margin-right: 5px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .delete-tooltip {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // padding-top: 18px;
    cursor: pointer;
    padding-left: 5px;
  }
  .order-button {
    display: flex;
    margin-left: auto;
    padding-right: 10px;
  }

  .status-massage-tooltip {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // padding-top: 13px;
    cursor: pointer;
  }
  .treatment-plan-title {
    display: flex;
    gap: 3px;
  }
  .ipr-list-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }
}

.no-treatment-details-container {
  width: 100%;
  padding-bottom: 6px;

  border-radius: 10px;
  .no-treatment-plan-container {
    height: 300px;
    background-color: white;
    border-radius: 10px;

    .no-treatment-plan {
      border-bottom: 1px solid #f0f0f0;
      height: 3.65rem;
      text-align: left;
      padding: 1rem;
      border-radius: 10px;
      .no-treatment-plan-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }
      .no-treatment-plan-title {
        margin-bottom: unset !important;
      }
      .buttons-container {
        margin-top: -0.5rem;
      }
    }
    .empty-plan-component {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // height: 60%;
      background: #fafafa ;
      border: 1px dashed #d7d6d6 ;
      border-radius: 13px;
      margin: 1rem;
      padding: 1rem;
      color: #000;
      
    }
  }
}


.treatment-setup-update-link-form{
  .treatment-file{
    border: 1px solid #d9d9d9;
    background: #ffffff;
    padding: 6px 11px;
    border-radius: 4px;
    margin: 8px 0px 20px 0px;
    span{
      background-color: rgb(221, 250, 255);
    color: #000;
    padding: 5px 9px 8px 11px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 12px;
    }
  }
}