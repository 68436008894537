:global {
  h5 {
    margin: 0px !important;
    font-size: 14px;
    line-height: auto;
  }

  p {
    margin: 0px !important;
    line-height: auto;
  }
}

.lab-chat__container {
  height: calc(100vh - 180px);
  overflow-y: auto;
  padding-right: 10px;
}

.chat__message {
  display: flex;
}

.chat-message__content {
  width: 230px;
  padding: 10px;
  border-radius: 10px;
  margin: 4px 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.chat__sender__comment {
  justify-content: flex-end;
}

.chat__receiver__comment {
  justify-content: flex-start;
}

.chat-message__name {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
}

.chat-message__text {
  font-size: 14px;
  margin-bottom: 5px;
}

.chat-message__date {
  color: #a1a8b0;
  display: block;
  font-size: 12px;
  margin-left: auto;
}

// text editor styles

.comment-editor {
  background: #fff;
  border-radius: 5px;
  padding: 0px 10px;
  display: flex;
  position: relative;
  align-items: flex-end;
  word-break: break-all;

  :global(.ql-toolbar) {
    border: 1px solid #fff;
  }
  :global(.ql-container.ql-snow) {
    border: 1px solid #fff;
  }
  :global(.ql-editor) {
    min-height: 44px;
    max-height: 100px !important;
    word-break: break-word;
  }
}

.upload-icon {
  margin-bottom: 8px;
}
.send-icons {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.chat-container__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.chat-container__empty-text {
  color: #acacacb8;
}
