.onboarding-container {
  h3{
    margin: 0px;
  }
  .header-row{
    margin: 0rem 0px 1rem 0px;
  }
  .footer-buttons{
    margin-top: 1rem;
    
    .footer-btn{
    width: 10rem;
    height: 2.5rem;
    }
    .cancel-btn{
      margin-right: 2rem;
    }
  }
  // width: 60%; /** commented to complete  the requirement of the manage staff tab in settings */

  margin: 1rem auto 0 auto;

  .ant-form-item-required {
    color: #798c96 !important;
  }

  .ant-form-item {
    color: #798c96 !important;
  }

  .ant-form-item-label {
    color: #798c96 !important;
  }

  .ant-tabs-nav-wrap {
    height: 57px !important;
    border-radius: 6px !important;
    justify-content: center !important;
    background: #fff !important;

    .ant-tabs-tab {
      margin: 0 50px !important;
    }
  }
}

.product-services-container {
  // padding-top: 1rem;
  // padding-left: 2rem;
  // padding-right: 2%;
  // background-color: white;
  // padding-bottom: 3.5rem;
  // border-radius: 6px;
  // padding: 20px 20px 2rem 20px;

  h1 {
    font-family: 'Poppins', sans-serif !important;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
  }

  .product-service-form {
    .product-form-section{
      background-color: white;
      border-radius: 6px;
      padding: 10px 20px 10px 20px;
    }
    .ant-form-item-explain-error{
      text-align: left !important;
    }
    .ant-divider{
      margin: 20px 0 0px 0px !important;
    }
    label {
      min-width: 160px !important;
    }

    .ant-form-item {
      margin-bottom: 5px !important;
    }


    .package-radio{
      label {
        min-width: auto !important;
      }
    }
  }
    .lab-product-selectbox {
      .ant-select-selection-item {
        .delete-icon {
          display: none !important;
        }
      }
    }
}

.lab-product-selectbox {
  .ant-select-selection-item {
    .delete-icon {
      display: none !important;
    }
  }
}

.lab-product-selectbox-item {
  display: flex;
  justify-content: space-between;
}