.login-btn-container {
  //   margin-top: 1rem;
}

.login-container {
  background-color: white;
  height: 100%;
  // .ant-input-affix-wrapper{
  //   height: 42px !important;
  // }
  .login-footer{
   .terms-line{
    text-align: center;
    color: #1D1D1D;
    font-size: 12px;
    margin: 12px 0px !important;
   }
  }
}

.login-button {
  width: 100% !important;
  height: 2rem !important;
  border-radius: 5px !important;
  background: #464646 !important;
  border-style: none !important;
  height: 2.5rem !important;
  font-weight: 700 !important;
}

.login-image-container {
  // height: 100%;

  height: 100vh;
  object-fit: cover;
}

.login-form-forgot {
  float: right !important;

  color: #00c6e9;
  &:hover{
    cursor: pointer;
  }

}
.ant-col-rtl .login-form-forgot {
  float: left !important;
}

.image {
  height: 100vh !important;
  max-height: 1300px;
  object-fit: cover;
  object-position: top;
  width: 100% !important;
}

.login-title-container {
  text-align: left;
  // margin-left: 16%;
  margin-bottom: "3rem";
  //   background-color: blue;
  // margin-top: 2rem;
  h2{
    margin-top: 32px !important;
    // margin-top: 30px !important;
    margin-bottom: 1.6px !important;
  }
  p{
    margin: 14px 0px !important;
  }
}

@media only screen and (max-width: 992px) {
  .image {
    display: none;
  }
  .login-title-container {
    text-align: center;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1450px) {
  .login-image-container {
    height: 100%;

    // height: 100vh;
    object-fit: cover;
  }
}

@media only screen and (max-width: 570px) {
  .login-btn-container {
    padding-left: 1.4rem;
  }
  .login-form {
    padding-right: 0.8rem !important;
  }
}

@media only screen and (max-width: 480px) {
  // .title-container {
  //   text-align: left;

  //   padding-left: 4%;
  // }
  .login-logo {
    margin-left: 0 !important;
  }
  .login-form {
    padding-right: 0.8rem !important;
  }

  .login-btn-container {
    width: 100%;
    // margin: 7% auto 0 0;
    padding-left: 0.9rem;
  }
  .logo-title-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
