// .clinicalPics{
//     .ant-upload {
//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: center;
//         background: rgba(0, 0, 0, 0.02);
//         border: 1px dashed #d9d9d9;
//         border-radius: 4px;
//         cursor: pointer;
//         transition: border-color 0.3s;
//         min-height: 122px !important;
//         width: 185px;
//       }

//     .ant-upload-list.ant-upload-list-picture {
//         display: flex;
//         flex-wrap: wrap;
//     }
//     .ant-upload-list-picture-container {
//         margin-right: 10px;
//     }
//     .ant-upload-list-item.ant-upload-list-item-undefined.ant-upload-list-item-list-type-picture {
//         height: 60px;
//         min-width: 200px;
//     }
//     .ant-upload {
//         border-radius: 50px !important;
//         display: flex !important;
//         justify-content: center !important;
//         width: 22px !important;
//          background: #00c6ec52 !important;
//         position: absolute !important;
//         left: 60px !important;
//         top: 12px !important;
//         cursor: pointer !important;
//     }
// }
// .clinicalPics-align{
//     .ant-upload {
//     background: #de8754 !important;
//     svg{
//         path{
//             fill: #fff !important;
//         }
//     }
//     }
// }

.clinic-file-upload {
  .ant-upload-list-item {
    height: 120px !important;
  }
  .ant-upload {
    // position: relative;
    // text-align: center;
    // border: 1px dashed #d9d9d9;
    // border-radius: 4px;
    // transition: border-color 0.3s;
    // min-height: 114px !important;
    // width: 185px;
    // background: rgba(0, 0, 0, 0.02) !important;
    // cursor: pointer;
    width: 218px !important;
    height: 114px !important;
  }

  .ant-upload-select {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 114px;
    width: 218px !important;
  }

  .scan-upload-text {
    color: rgba(34, 48, 50, 0.72) !important;
    text-align: center;
    font-family: "Mulish", sans-serif;
    font-size: 12px !important;
    padding: 0 6px;
    font-weight: 600;
    line-height: normal;
  }

  .ant-upload-list-picture-card {
    margin-left: 240px;
    display: flex;
    overflow-x: auto;
    height: 165px;
    flex-wrap: nowrap !important;
  }

  // .ant-upload{

  //
  // }
}
