.ipr-checks-scroll{
  height: 300px;
  overflow: scroll;
  margin-bottom: 1rem;
  
}

.aligner-scroll-container{
  height: 300px;
  overflow: scroll;
  padding-top: 23px;
}

.ipr-check-row {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    // gap: 10px;
    .ant-checkbox-wrapper{
    //   align-self: flex-start !important;
        justify-self: center !important;
    }
    .patient-question{
      min-width: 250px;
      text-align: left;
    }
    .ipr-circle {
      align-self: center;
      // align-self: flex-end !important;
      // justify-self: flex-end !important;
      border-radius: 7px;
      // background: #000;
      width: 14px;
      height: 14px;
    }

  }

.ipr-detail-container {
    background: #fff;
    padding-top: 15px;
    border-radius: 8px;
 

    .aligners-container {

      .aligner-rows {
        display: flex;
        align-items: center;

        .aligner-info {
          display: flex;
          align-items: center;
          position: absolute;
          top: 4px;
          left: 0px;

          h2 {
            color: #000;
            font-family: "Mulish", sans-serif;
            font-size: 12px;
            font-weight: 600;
            margin: 0;
          }

          p {
            color: #000;
            font-family: "Mulish", sans-serif;
            font-size: 10px;
            font-weight: 600;
            margin: 0;
          }

          .aligner-num {
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            width: 35px;
            position: relative;
            color: #fff;
            z-index: 15;
            font-weight: 700;
          }
        }
      }

      .aligner-row {
        align-items: flex-end;
        display: grid;
        grid-template-columns: repeat(15, 1fr);
        column-gap: 1px;
        row-gap: 0px;
        position: relative;
        width: 90%;
        margin: 0px auto 0px auto;

      }

      .aligner-circle {
        width: 30px;
        height: 28px;
        line-height: 25px;
        border-radius: 50%;
        text-align: center;
        font-weight: 600;
        color: #fff;
        align-self: center;
        justify-self: center;
        margin-bottom: 10px;
        position: relative;
        // &::after{
        //   content: '';
        //   background: #777777;
        //   height: 20px;
        //   width: 1px;
        //   position: absolute;
        //   z-index: 12;
        //   left: 50%;
        // }
      }

      .aligner-rows {
        position: relative;
        cursor: pointer;
        width: 100%;
        border-radius: 8px;
        padding: 5px 0px;

        .del-btn {
          position: absolute;
          top: 5px;
          right: -40px;
          border: none;
          box-shadow: none;
          background: transparent;
          z-index: 2;
        }
      }

      .selected-aligner {
        background: #e1f0f3;

      }
    }

    .aligner-0-upper {
      position: relative;

      // &::before {
      //   content: '';
      //   // background-image: url('../../../images/IprArrow.png');
      //   position: absolute;
      //   bottom: -29px;
      //   z-index: 12;
      //   width: 18px;
      //   height: 30px;
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   left: 25%;
      // }
    }

    .aligner-0-lower {
      position: relative;

      // &::before {
      //   content: '';
      //   // background-image: url('../../../images/IprArrow.png');
      //   transform: rotate(180deg);
      //   position: absolute;
      //   top: -30px;
      //   z-index: 12;
      //   /* left: -6px; */
      //   width: 24px;
      //   height: 30px;
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   right: 30%;
      // }
    }

    .aligner-upper{
      &::after{
        top: -20px;
      }
    }
    .aligner-lower{
      &::after{
        bottom: -20px;
      }
    }

    .ipr-row {
      .plus {
        width: 21px;

        svg {
          width: 21px;
        }

        position: absolute;
        top: 40%;
        right: -15%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        color: #000;
        font-size: 12px;
        font-weight: 700;
        z-index: 1;
      }
    }
  }


  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
    //   height: 24px;
    // width: 24px;
    // }
    .ipr-detail-container {
      .teethChart-container .teethRow-container .teethRow-one svg {
        width: 28px;
      }
    }
  }
  
  @media only screen and (min-width: 1300px) and (max-width: 1400px) {
    // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
    //   height: 24px;
    // width: 24px;
    // }
    .ipr-detail-container {
      .teethChart-container .teethRow-container .teethRow-one svg {
        width: 30px;
      }
    }
  }
  
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
    // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
    //   height: 25px;
    // width: 25px;
    // }
    .ipr-detail-container {
      .teethChart-container .teethRow-container .teethRow-one svg {
        width: 31px;
      }
    }
  }
  
  @media only screen and (min-width: 1500px) and (max-width: 1600px) {
    // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
    //   height: 28px;
    // width: 28px;
    // }
    .ipr-detail-container {
      .teethChart-container .teethRow-container .teethRow-one svg {
        width: 34px;
      }
    }
  }
  
  @media only screen and (min-width: 1601px) and (max-width: 1700px) {
    // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
    //   height: 28px;
    // width: 28px;
    // }
    .ipr-detail-container {
      .teethChart-container .teethRow-container .teethRow-one svg {
        width: 38px;
      }
    }
  }
  @media only screen and (min-width: 1701px) and (max-width: 1800px) {
    // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
    //   height: 30px;
    // width: 30px;
    // }
    .ipr-detail-container {
      .teethChart-container .teethRow-container .teethRow-one svg {
        width: 41px;
      }
    }
  }
  
  @media only screen and (min-width: 1901px) {
    .ipr-detail-container {
      .teethChart-container .teethRow-container .teethRow-one svg {
        width: 47px;
      }
    }
  }