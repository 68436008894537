@import "../variables.scss";
.ant-layout-sider {
  // background-color: $primaryColor !important;
}

.custom-menu {
  li {
    margin: 0 !important;
    width: 100% !important;
    text-align: center !important;
    // margin-bottom: 8px !important;

    .ant-menu-title-content {
      margin: 0 !important;
      display: block;
    }
  }
}

.sidebar-menu-items {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: auto !important;
  padding-top: 0.5rem !important;
  // background-color: red;
  margin-top: 2px !important;
  p {
    margin: 0;
  }
}

.sidebar-menu-items:hover {
  background-color: white;
}

.sidebar-icon {
  font-size: 1.5rem !important;
  // width: 100% !important;
  // text-align: center!important;
}

.sidebar-container {
  .ant-menu {
    .ant-menu-item {
      border-radius: 0;
    }
  }
}
