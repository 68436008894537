.upload-file-scan-container {
  .ant-input-group-addon {
    background-color: white !important;
    border-left-style: none !important;
  }
  .ant-input {
    // border-right-style: none !important;
  }
  .ant-upload-list-item {
    margin: 0 2rem 0.5rem 2rem !important;
  }

  .ant-upload-list-item {
    clear: both !important;
  }
  margin-top: -15px;
  // margin-left: 2rem;
  // width: calc(95% - 1rem);
  padding-right: 27px;
  padding-left: 20px;
  width: 100%;
}

.upload-files-title {
  margin: 1.5rem 0.5rem 0 2rem !important;

  font-family: "Poppins", sans-serif !important;
}

.dragger-scan-title {
  margin: 1.5rem 0.5rem 0 2rem !important;
  font-weight: 500;
}

.url-scan-title {
  margin: 1.5rem 38% 0 0 !important;
  // text-align: right;
  // background-color: blue !important;
  font-weight: 600;
}

.scan-upload-text {
  color: rgba(34, 48, 50, 0.72);
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 12px !important;
  padding: 0 6px;
  font-weight: 600;
  line-height: normal;
  // display: inline-block !important;
}
// .scan-upload-drag-icon span {
//    color: gray !important;
//    font-size: 12px !important;
// }

.scan-container {
  font-family: "Mulish", sans-serif;
  padding: 10px;
  width: 15rem;
}

.icon-margin{
  margin: 14px 0 16px 0;
}
.scan-subheader {
  text-align: start;
  color: gray;
}

.required {
  &:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.scan-uploader {
  border-radius: 10px !important;
  height: 12rem !important;
  width: 15rem !important;
  // height: 4rem !important;
  // width: 18rem !important;
  // display: inline-block !important;
}
.scan-form-heading {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: #222222;
  font-size: 18px;
  margin-left: 1rem;
  padding-top: 25px;
}


