
.staff-view-container{
  margin: 20px;
  .heading {
    color: #000;
    font-family: "Poppins", "Mulish";
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
    text-align: left;
}
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  border-radius: 6px 6px 0 0;
}
.header-container__title {
  margin: 0;
}
