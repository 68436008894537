.filterContainer {
    width: 300px;
    padding: 10px;

    .applyBtn {
        width: 6rem;
        display: block;
        margin: 10px 0px 0px auto;
    }

    .filterList {
        max-height: 225px;
        margin-top: 10px;

    }
    .filterSearch{
        input{
            height: 40px;
        }

    }
}