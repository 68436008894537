.print-info-status {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    .print-date {
        font-weight: 800;
        color: #757575;
        font-size: 14px;
        font-family: 'Poppins', sans-serif ;
        padding-top: 4px;
    }
    svg {
        visibility: hidden;
    }

    &:hover {
        svg {
            visibility: visible;
        }
    }
}