$alignerColor-1: #00C6E9;
$alignerColor-2: #F2D076;
$alignerColor-3: #334A50;
$alignerColor-4: #D6DBD7;
$alignerColor-5: #DA786B;
$alignerColor-6: #B3B689;

$alignerColor-7: #7A8B67;
$alignerColor-8: #FFD8C2;
$alignerColor-9: #D98E6F;
$alignerColor-10: #647F6E;

$alignerColor-11: #F4B69D;
$alignerColor-12: #B8CECC;
$alignerColor-13: #3A615E;
$alignerColor-14: #9F5E64;
$alignerColor-15: #C58557;

$alignerColor-16: #EACFC4;
$alignerColor-17: #C48849;
$alignerColor-18: #cfb7ad;
$alignerColor-19: #DB7858;

$alignerColor-20: #F1AE79;
$alignerColor-21: #7C5E60;
$alignerColor-22: #F4E2CE;
$alignerColor-23: #B2B7A0;
$alignerColor-24: #F4E2CE;
$alignerColor-25: #E9C0AA;


$alignerColor-26: #7574A8;
$alignerColor-27: #6988C5;
$alignerColor-28: #69C5C5;
$alignerColor-29: #A2C569;
$alignerColor-30: #C5697F;



.booking-form-container {
  width: 100%;
  // margin: 0 1rem 1rem 1rem;
  margin-top: 1rem;
}

.booking-form-title {
  margin: 1.5rem 0.5rem 0 2rem !important;

  font-family: 'Poppins', sans-serif !important;
}

.booking-form-title-container {
  width: 100%;
  // border-bottom-style: solid;

  margin: 0 1rem 0 0;
  border-width: 1px;
  border-color: rgba(161, 182, 218, 0.47);
  border-radius: 6px 6px 0 0;

  background-color: white;
}

.parent-form-item {
  margin-bottom: -30px !important;
  width: 100% !important;
  margin-top: 2rem !important;
}

.booking-form {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2%;
  // border-radius: 0 0 6px 6px;
  background-color: white;
  padding-bottom: 1.5rem;
}

.test {
  .ant-input:focus {
    border-style: none !important;
    border-color: white !important;
  }

  .ant-form-item {
    border-style: solid !important;
  }
}


// Add Treatment Setup ========================================
.ipr-chart-drop {
  .ant-dropdown-menu-item {
    text-align: center !important;
    border-bottom: 1px solid #ECECEC;

    &:hover {
      background-color: #f5f5f5 !important;
    }
  }
}

.scroll-ipr-chart{
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.treatment-setup-modal {

  .chart-header {


    .ant-form-item {
      margin-bottom: 0px !important;
    }

    .header-select {
      width: 154px;
      text-align: left;
    }

  }
.treatment-container{
  background: #fafafa;
  border: 1px dashed #d7d6d6 ;
  box-sizing: border-box ;
  border-radius: 13px ;
  padding: 1rem;
  margin: 0 5px;
  margin-bottom: 10px;
  .lab-product-selectbox {
    .ant-select-selection-item {
      .delete-icon {
        display: none;
      }
    }
  }

  .lab-product-selectbox-item {
    display: flex;
    justify-content: space-between;
  }
  
  .treatment-label{
    color: #798c96;
    font-weight: 400;
    font-size: 14px;
    font-family: "Mulish", sans-serif;
    margin-bottom: 0px;
  }
  .treatment-info{
    color: rgba(34, 34, 34, 0.72);
    font-weight: 800;
    font-size: 14px;
    font-family: "Mulish", sans-serif;
  }
}



  .ant-btn[disabled]{
     svg{
      path{
    fill: #d9d9d9;
      }
    }
  }

  .overlay {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 10;
  }

  .header-select-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0px;
    margin-right: -20px;

    .header-select {
      width: 140px;
      text-align: left;
    }
  }

  .treatment-setup-form {
    padding: 10px;

   .dynamic-products{
    border: 1px dashed #D7D6D6;
    border-radius: 10px;
    margin:10px 0px;
    .cost-container{
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 5px;
      margin-bottom: 8px;
      label{
        color: rgba(0, 0, 0, 0.88);
      }
    }

    .border-line{
      border-top: 1px dashed #D7D6D6;
      width: 100%;
    }
    .totals{
      .total-label{
        color: #9CA5BC;
        font-size: 16px;
       margin: 10px 0px;
       text-align: left;
       padding-top: 10px;
      }
      h2{
        font-size: 14px;
        font-weight: 700;
        margin: 0px 0px 15px 0px;
        text-align: left;
      }
    }
    .ant-input-number{
      width: 100%;
    }
    .ant-form-item{
    margin-bottom: 14px !important;
    }
    .addOn-btn {
      display: flex;
      align-items: center;
      border: none;
      color: #828282;
      font-weight: 400;
      box-shadow: none;
      margin-left: auto;
      padding: 0px;
      svg {
          margin-right: 0px;
      }
  }
   }

    .treatment-file{
      border: 1px solid #d9d9d9;
      background: #ffffff;
      padding: 6px 11px;
      border-radius: 4px;
      margin: 8px 0px 0px 0px;
      span{
        background-color: rgb(221, 250, 255);
        color: #000;
        padding: 5px 9px 8px 11px;
        border-radius: 30px;
        font-weight: 600;
        font-size: 12px;
      }
    }

    .treatment-collapse {
      background: #fff !important;


      .add-guideBtn {
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        border-radius: 30px;
        height: 2.5rem;
        flex-direction: row-reverse;
        gap: 10px;
        margin-top: 20px;
      }

      .ant-radio-button-wrapper:first-child {
        border-radius: 8px 0 0 8px !important;
      }

      .ant-radio-button-wrapper:last-child {
        border-radius: 0 8px 8px 0 !important;
      }

      .ant-radio-group {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ipr-radio {
        margin: 14px 0px;

        .ant-radio-button-wrapper {
          min-width: 150px !important;
          text-align: center !important;
          height: 42px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          // border: 1px solid #77849E !important;

          .ant-radio-button {}
        }
      }


      .ant-collapse-item {
        border-bottom: 0px !important;
      }

      .ant-collapse-header {
        display: flex !important;
        padding: 0px !important;
        min-height: 40px !important;
      }

      // .ant-collapse-arrow {
      //   margin-top: 12px !important;
      //   padding: 0px !important;
      // }

      .ant-collapse-content-box {
        // padding-left: 0px;
      }

    }

    .attachment-values-container {
      .delete-btn {
        border: none !important;
        box-shadow: none !important;
        background: none;

        svg {
          path {
            fill: rgb(0, 0, 0) !important;
          }
        }

        &:hover,
        &:active {
          svg {
            path {
              fill: red !important;
            }
          }
        }
      }

      .toothName {
        border-radius: 6px;
        // background: #00C6E9 !important;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        text-align: center;
        padding: 3px;
        margin: 0;
      }

      .ant-select {
        width: 100%;
        text-align: left;
      }
    }

  }
}

.aligners-container {

  .aligner-rows {
    display: flex;
    align-items: center;

    .aligner-info {
      display: flex;
      align-items: center;
      position: absolute;
      top: 10px;
      left: -60px;

      h2 {
        color: #000;
        font-family: "Mulish", sans-serif;
        font-size: 12px;
        font-weight: 600;
        margin: 0;
      }

      p {
        color: #000;
        font-family: "Mulish", sans-serif;
        font-size: 10px;
        font-weight: 600;
        margin: 0;
      }

      .aligner-num {
        width: 25px;
        height: 24px;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        font-weight: 600;
        margin-left: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      .aligner-1-num {
        background: $alignerColor-1;
      }

      .aligner-2-num {
        background: $alignerColor-2;
      }

      .aligner-3-num {
        background: $alignerColor-3;
      }

      .aligner-4-num {
        background: $alignerColor-4;
      }

      .aligner-5-num {
        background: $alignerColor-5;
      }

      .aligner-6-num {
        background: $alignerColor-6;
      }

      .aligner-7-num {
        background: $alignerColor-7;
      }

      .aligner-8-num {
        background: $alignerColor-8;
      }

      .aligner-9-num {
        background: $alignerColor-9;
      }

      .aligner-10-num {
        background: $alignerColor-10;
      }

      .aligner-11-num {
        background: $alignerColor-11;
      }

      .aligner-12-num {
        background: $alignerColor-12;
      }

      .aligner-13-num {
        background: $alignerColor-13;
      }

      .aligner-14-num {
        background: $alignerColor-14;
      }

      .aligner-15-num {
        background: $alignerColor-15;
      }

      .aligner-16-num {
        background: $alignerColor-16;
      }

      .aligner-17-num {
        background: $alignerColor-17;
      }

      .aligner-18-num {
        background: $alignerColor-18;
      }

      .aligner-19-num {
        background: $alignerColor-19;
      }

      .aligner-20-num {
        background: $alignerColor-20;
      }

      .aligner-21-num {
        background: $alignerColor-21;
      }

      .aligner-22-num {
        background: $alignerColor-22;
      }

      .aligner-23-num {
        background: $alignerColor-23;
      }

      .aligner-24-num {
        background: $alignerColor-24;
      }

      .aligner-25-num {
        background: $alignerColor-25;
      }

      .aligner-26-num {
        background: $alignerColor-26;
      }

      .aligner-27-num {
        background: $alignerColor-27;
      }

      .aligner-28-num {
        background: $alignerColor-28;
      }

      .aligner-29-num {
        background: $alignerColor-29;
      }

      .aligner-30-num {
        background: $alignerColor-30;
      }
    }

    .attachment-info {
      left: -77px;
    }
  }

  .aligner-row {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    column-gap: 1px;
    row-gap: 0px;
    position: relative;
    width: 90%;
    margin: 0px auto 0px auto;
  }


  .attachment-row {
    grid-template-columns: repeat(16, 1fr);
    width: 95%;
    margin-left: 20px;
  }

  .aligner-0-upper {
    position: relative;

    &::before {
      content: '';
      background-image: url('../../images/IprArrow.svg');
      position: absolute;
      bottom: -29px;
      z-index: 12;
      width: 24px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      left: 20%;
      // background-color: #ffff;
    }
  }

  .aligner-0-lower {
    position: relative;

    &::before {
      content: '';
      background: transparent;
      background-image: url('../../images/IprArrow.svg');
      transform: rotate(180deg);
      position: absolute;
      top: -30px;
      z-index: 12;
      width: 24px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      left: 20%;
      // background-color: #fff;
    }
  }

  .aligner-box {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 46px;
    position: relative;
    color: #fff;
    z-index: 15;
    font-weight: 700;
    background: #fff;

    &::after {
      content: '';
      background: #777777;
      height: 11px;
      width: 1px;
      position: absolute;
      z-index: 12;
      left: 50%;
    }
  }

  .aligner-boxupper {
    &::after {
      top: -11px;
    }
  }

  .aligner-boxlower {
    &::after {
      bottom: -11px;
    }
  }

  .aligner-rows {
    position: relative;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    padding: 5px 0px;

    .del-btn {
      position: absolute;
      top: 10px;
      right: -25px;
      border: none;
      box-shadow: none;
      background: transparent;
      z-index: 2;
    }
  }

  .selected-aligner {
    background: #e1f0f3;

  }
}

.aligner-1 {
  border: 1px solid $alignerColor-1;
}

.aligner-2 {
  border: 1px solid $alignerColor-2;
}

.aligner-3 {
  border: 1px solid $alignerColor-3;
}

.aligner-4 {
  border: 1px solid $alignerColor-4;
}

.aligner-5 {
  border: 1px solid $alignerColor-5;
}

.aligner-6 {
  border: 1px solid $alignerColor-6;
}

.aligner-7 {
  border: 1px solid $alignerColor-7;
}

.aligner-8 {
  border: 1px solid $alignerColor-8;
}

.aligner-9 {
  border: 1px solid $alignerColor-9;
}

.aligner-10 {
  border: 1px solid $alignerColor-10;
}

.aligner-11 {
  border: 1px solid $alignerColor-11;
}

.aligner-12 {
  border: 1px solid $alignerColor-12;
}

.aligner-13 {
  border: 1px solid $alignerColor-13;
}

.aligner-14 {
  border: 1px solid $alignerColor-14;
}

.aligner-15 {
  border: 1px solid $alignerColor-15;
}

.aligner-16 {
  border: 1px solid $alignerColor-16;
}

.aligner-17 {
  border: 1px solid $alignerColor-17;
}

.aligner-18 {
  border: 1px solid $alignerColor-18;
}

.aligner-19 {
  border: 1px solid $alignerColor-19;
}

.aligner-20 {
  border: 1px solid $alignerColor-20;
}

.aligner-21 {
  border: 1px solid $alignerColor-21;
}

.aligner-22 {
  border: 1px solid $alignerColor-22;
}

.aligner-23 {
  border: 1px solid $alignerColor-23;
}

.aligner-24 {
  border: 1px solid $alignerColor-24;
}

.aligner-25 {
  border: 1px solid $alignerColor-25;
}

.aligner-26 {
  border: 1px solid $alignerColor-26;
}

.aligner-27 {
  border: 1px solid $alignerColor-27;
}

.aligner-28 {
  border: 1px solid $alignerColor-28;
}

.aligner-29 {
  border: 1px solid $alignerColor-29;
}

.aligner-30 {
  border: 1px solid $alignerColor-23;
}

//Fills

.aligner-1-fill {
  background: $alignerColor-1 !important;
}

.aligner-2-fill {
  background: $alignerColor-2 !important;
}

.aligner-3-fill {
  background: $alignerColor-3 !important;
}

.aligner-4-fill {
  background: $alignerColor-4 !important;
}

.aligner-5-fill {
  background: $alignerColor-5 !important;
}

.aligner-6-fill {
  background: $alignerColor-6 !important;
}

.aligner-7-fill {
  background: $alignerColor-7 !important;
}

.aligner-8-fill {
  background: $alignerColor-8 !important;
}

.aligner-9-fill {
  background: $alignerColor-9 !important;
}

.aligner-10-fill {
  background: $alignerColor-10 !important;
}

.aligner-11-fill {
  background: $alignerColor-11 !important;
}

.aligner-12-fill {
  background: $alignerColor-12 !important;
}

.aligner-13-fill {
  background: $alignerColor-13 !important;
}

.aligner-14-fill {
  background: $alignerColor-14 !important;
}

.aligner-15-fill {
  background: $alignerColor-15 !important;
}

.aligner-16-fill {
  background: $alignerColor-16 !important;
}

.aligner-17-fill {
  background: $alignerColor-17 !important;
}

.aligner-18-fill {
  background: $alignerColor-18 !important;
}

.aligner-19-fill {
  background: $alignerColor-19 !important;
}

.aligner-20-fill {
  background: $alignerColor-20 !important;
}

.aligner-21-fill {
  background: $alignerColor-21 !important;
}

.aligner-22-fill {
  background: $alignerColor-22 !important;
}

.aligner-23-fill {
  background: $alignerColor-23 !important;
}

.aligner-24-fill {
  background: $alignerColor-24 !important;
}

.aligner-25-fill {
  background: $alignerColor-25 !important;
}

.aligner-26-fill {
  background: $alignerColor-26 !important;
}

.aligner-27-fill {
  background: $alignerColor-27 !important;
}

.aligner-28-fill {
  background: $alignerColor-28 !important;
}

.aligner-29-fill {
  background: $alignerColor-29 !important;
}

.aligner-30-fill {
  background: $alignerColor-30 !important;
}



.ipr-row {
  .plus {
    width: 21px;

    svg {
      width: 21px;
    }

    position: absolute;
    top: 40%;
    right: -15%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    z-index: 1;
  }

  .plusupper {
    &::after {
      top: -45px;
      content: '';
      background: #777777;
      height: 45px;
      width: 1px;
      position: absolute;
      z-index: 12;
      left: 110%;

    }

  }

  .pluslower {
    &::after {
      left: 110%;
      bottom: -40px;
      content: '';
      background: #777777;
      height: 45px;
      width: 1px;
      position: absolute;
      z-index: 12;
      left: 110%;
    }

  }

  .teethChart-container .teethRow-container .teethRow-one .teeth-structure span {
    top: 42%;
  }


  .attachment-plus {
    svg {
      width: 19px !important;
      visibility: hidden;
    }

    width: 21px;
    position: absolute;
    top: 21%;
    right: 2%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    z-index: 20;


  }

  .circlePath{
    fill: #fff !important;
   }

}


//=========================================
@media only screen and (max-width: 992px) {
  .booking-form-container {
    margin-top: 1rem;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .inline-form-left {
    display: block !important;
    width: auto !important;
    margin: 0 0.5rem 0 0 !important;
  }

  .inline-form-right {
    display: block !important;
    width: auto !important;
    margin: 2rem 0.5rem 1.5rem 0 !important;
  }

  .booking-form-title {
    margin: 1.5rem 1rem 1.5rem 1rem !important;
  }

  .booking-form {
    padding-left: 1rem;
  }
}

@media only screen and (min-width: 1000px) {
  .treatment-setup-form {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 50px;
    }
  }
}
