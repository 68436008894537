.activity-container {
  height: 76vh;
  overflow-y: auto;

  padding-right: 8px;

  :global(.heading-name) {
    font-weight: bold;
    text-transform: capitalize;
  }
}

.activity-pill {
  background: white;
  margin: 8px 0;
  border-radius: 4px;
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 12fr;
  gap: 10px;
  :global(.mention) {
    border-radius: 4px;
    background-color: #d3e1eb!important;
    // background-color: #262424 !important;
    // color: #fff;
    color: black;
    padding: 0;
  }
}

.activity__icon {
  justify-self: center;
  align-self: flex-start;
}

.activity__description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}
.activity__text-container {
  align-self: flex-start;
}

.activity__text {
  color: #798c96;
  word-break: break-word;
  :global(p) {
    margin: 0;
  }
}

.activity__time {
  font-size: 10px;
  color: #798c96;
  align-self: flex-start;
  flex-shrink: 0;
}
