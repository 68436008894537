.teethChart-container {
    background: #fff;
    border-radius: 8px;
    padding: 0px 16px 20px 16px;
    .addOn-btn {
        display: flex;
        align-items: center;
        border: none;
        color: #828282;
        font-weight: 400;
        box-shadow: none;
        margin-right: auto;
        padding: 0px;
        background: none;
        svg{
            margin-right: 0px;
        }
    }

    .ant-collapse-arrow {
        margin-top: 17px !important;
    }


    .ant-collapse {
        background: #fff;
    }

    .ant-collapse-header {
        padding: 10px 10px !important;
    }

    .ant-collapse-expand-icon {
        margin-top: 7px !important;
    }


    .ant-divider {
        margin: 5px 0 !important;
    }

    .chart-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
          padding: 10px 0px 0px 0px;
          margin-bottom: 10px;
        // padding-top: 10px;


        h1 {
            margin: 0;
            color: #000;
            font-family: "Mulish", sans-serif;
            font-size: 15px;
            font-weight: 700;
        }

        .header-select {
            width: 154px;
            text-align: left;
        }

    }

    .teethRow-container {
        position: relative;
        margin-top: 20px;
        z-index: 11;
        .teethRow-bgOne {
            position: absolute;
            top: 3px;
            border-radius: 30px;
            background: #FFD4D4;
            margin: 0px;
            padding: 0px 20px 0px 10px;
            height: 32px;
            width: 100%;
        }

        .teethRow-bgTwo {
            position: absolute;
            bottom: 5px;
            border-radius: 30px;
            background: #FFD4D4;
            width: 100%;
            margin: 0px;
            padding: 0px 20px 0px 10px;
            height: 32px;
            z-index: 1;
        }

        .teethRow-one {
            display: grid;
            grid-template-columns: repeat(16, 1fr);
            column-gap: 0px;
            row-gap: 0px;
            top: 2px;
            z-index: 2;
            position: relative;
            margin: 0 10px;

            .tooth-sides {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                top: 55px;
                width: 98%;

                h2 {
                    margin: 0;
                    color: #000;
                    font-size: 15px;
                    font-weight: 700;

                }
            }

            .teeth-structure {
                position: relative;

                span {
                    position: absolute;
                    top: 38%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                    color: #000;
                    font-size: 12px;
                    font-weight: 700;
                }

                svg {
                    cursor: pointer;
                }
                &:hover{
                    svg{
                      visibility: visible !important;
                    }
              
                  }
            }

            .active-teeth {
                span {
                    color: #fff;
                }
                svg {
                    path {
                        fill: #00C6E9;
                    }
                }
            }
 
            .teeth-structure-smilealign{
                &.active-teeth {
                    svg {
                        path {
                            fill: #DE8754;
                        }
                    }
                }
            }
            .teeth-structure-ias{
                &.active-teeth {
                    svg {
                        path {
                            fill: #539ad1;
                        }
                    }
                }
            }
            .teeth-structure-aqua{
                &.active-teeth {
                    svg {
                        path {
                            fill: #00bff2;
                        }
                    }
                }
            }
            .teeth-structure-brushbar{
                &.active-teeth {
                    svg {
                        path {
                            fill: #0c77fa;;
                        }
                    }
                }
            }
        }
    }

    .addTooth-btn {
        // border-radius: 27px;
        // border: 1px solid #77888B;
        // background: #FFF;
        // width: 159px;
        // height: 38px;
        padding: 10px 20px;
        // color: #000;
         display: flex;
        // align-items: center;
        // justify-content: center;
        // text-align: center;
        // font-size: 16px;
        // font-weight: 500;
        margin-top: 13px;
        margin-left: auto;
    }
}

.ant-dropdown-menu-item {
    &:hover {
        background: #fff !important;
    }
}
@media only screen and (min-width: 700px) and (max-width: 800px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 37px;
                }
            }
        }
    }
    .ipr-row .plus {
        width: 19px;
        right: -25%;
    }
}

@media only screen and (min-width: 801px) and (max-width: 999px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 40px !important;
                }
            }
        }
    }
}


@media only screen and (min-width: 1000px) and (max-width: 1300px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 30px;
                }
            }
        }
    }
}


@media only screen and (min-width: 1301px) and (max-width: 1400px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 32px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 34px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 37px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1601px) and (max-width: 1700px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 41px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 46px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1801px) and (max-width: 1900px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 48px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1901px) {
    .teethChart-container {
        .teethRow-container {
            .teethRow-one {
                svg {
                    width: 49px;
                }
            }
        }
    }
}
