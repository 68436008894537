@import "./variables.scss";
.ant-input {
    padding: 7px 11px !important;
}



 .ant-select-selector {
    min-height: 40px !important;
    padding: 0 11px;
    width: 100%;
}
.ant-row {
    // margin: 0 0 22px !important; 
}
.ant-form-item{
//    margin-bottom: 22px !important;
}

// a {
//     color: $primaryColor;
//     &:hover{
//         color: $primaryColor; 
//     }
// }

.ant-select-single {
  height: auto; 
}


h1, h2, h3, h4, h5, h6 {
    margin-top: 0 !important;
}

 .ant-table {
    font-family: "Mulish", sans-serif !important;
}
.ant-table-thead{
    .ant-table-cell{
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600 !important;
    p{
        font-weight: 600 !important;
    }

        &::before{
            position: absolute;
            top: 50%;
            inset-inline-end: 0;
            width: 1px;
            height: 1.6em;
            background-color: #f0f0f0;
            transform: translateY(-50%);
            transition: background-color 0.2s;
            content: "";
        
    }
    }
}

p {
    margin-bottom: 1em;
    margin-top: 0;
}
.ant-input-number-input-wrap{
        padding: 8px 11px !important;
    input {
        padding: 0px !important;
    }
}

.ant-input-affix-wrapper{
    padding: 7px 11px !important;
    .ant-input {
        padding: 0px !important;
    }
}

.ant-input-search-button {
    height: 40px !important;
}

.ant-pagination-item{
    background-color: #fff !important;
    // border: 1px solid #d9d9d9 !important;
}

// .ant-pagination-item-active{
//  border: 1px solid $primaryColor !important;
// }

.search-bar-wrapper input {
    height: 40px !important;
}
.ant-modal-content{
    padding: 0px !important;
    border-radius: 3px !important;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px 0 #00000014, 0 9px 28px 8px #0000000d !important;
}

.ant-modal-body{
    padding: 24px !important;
}

.ant-modal-footer{
    background: #0000;
    border-radius: 0 0 3px 3px !important;
    border-top: 1px solid #f0f0f0 !important;
    padding: 10px 16px !important;
    text-align: right;

}

.ant-modal-header{
    background: #fff ;
    border-bottom: 1px solid #f0f0f0 !important;
    border-radius: 3px 3px 0 0 !important;
    color: #000000d9 !important;
    padding: 16px 24px !important;
}

.ant-upload-drag-icon{
    margin-bottom: 3px !important;
}

// .ant-btn-primary{
//   background: #00c6e9 !important;
//   color: #fff !important;
//   border: 1px solid #00c6e9 !important;
// }

.ant-picker{
    padding: 8px 11px !important;
}

.ant-radio-button-wrapper-checked{
color: #fff !important;
}

.ant-btn-disabled{
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    color: #00000040 !important;
    text-shadow: none !important;
}
.ant-tag {
    border: none !important;
}
// .ant-btn-primary[disabled], .ant-btn-primary[disabled]:active, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:hover {
//     background: #f5f5f5 !important;
//     border-color: #d9d9d9 !important;
//     box-shadow: none !important;
//     color: #00000040 !important;
//     text-shadow: none !important;
// }

.product-lab-price-input{
    .ant-input-number-input-wrap{
        height: 38px;
        input{
            height: unset;
        }
    }
}

.ant-btn, .ant-modal-footer{
    font-size: 14px !important;
    font-weight: 600;
}