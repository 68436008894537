.profile-form-container {
    margin: 20px 20px;
    .heading {
        font-family: "Poppins", "Mulish";
        margin: 0px;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 16px;
         margin-bottom: 1rem;
    }
}
