.upload-file-container {
  .ant-input-group-addon {
    background-color: white !important;
    border-left-style: none !important;
  }
  .ant-input {
    border-right-style: none !important;
  }
  // .ant-upload-list {
  //   margin: 1rem 2rem 0.5rem 2rem !important;
  //   // background-color: white;
  // }
  .ant-upload-list-text-container {
    background-color: white !important;
  }

  .ant-upload-list-item {
    clear: both !important;
  }
  // margin-top: -15px;
  // background-color: red !important;
  padding-bottom: 7rem;
  border-radius: 0 0 6px 6px;
}

.generic-links {
  margin-left: 2rem;
  // font-family: 'Poppins', sans-serif !important;
}

.upload-files-title {
  margin: 1.5rem 0.5rem 0 2rem !important;

  font-family: 'Poppins', sans-serif !important;
}

.dragger-title {
  margin: 1.5rem 0.5rem 0 2rem !important;
  font-weight: 500;
}

.url-title {
  margin: 1.5rem 22.3rem 0 0 !important;
  // text-align: right;
  // background-color: blue !important;
  font-weight: 600;
}
