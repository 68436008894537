@media only screen and (max-width: 992px) {
  .header-logo {
    margin-left: 0.8rem;
  }
}

.header-logo {
  width: 11.1rem;
  height: 3rem;

  margin-top: 0.5rem;
  margin-left: 1rem;
  object-fit: contain;
}


.titleSpan{
  display: flex;
  gap: 10px;
  align-items: center;
}
.addPatient-form{
  .ant-row{
    margin-bottom: 8px !important;
}
  .ant-form-item{
    margin-bottom: 0px !important;
  }
}