.search-bar-wrapper {

  button,
  input {
    height: 32px;
  }
}

.search-name-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 140px;
}