.treatment-upload{
    float: right;
    .ant-upload{
        background: #fff !important;
        border: none !important;
        height: 42px !important;

    }
    .ant-upload-list{
        background: #fff !important;
    }
}