.box-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.modal-wrapper {
    :global(.ant-modal-body) {
        height: 64vh;
        overflow-y: auto;
    }

}

.left-box {
    border-radius: 8px 0 0 8px;
    border: 1px solid #ccc;
    padding: 10px;
}

.right-box {
    border-radius: 0 8px 8px 0;
    border: 1px solid #ccc;
    padding: 10px;
    border-left: none;
}

.uploader-wrapper {
    margin-bottom: 16px;
}



.link-preview-wrapper {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 1rem;
}

.link-preview-container {
    height: 70px;
    padding: 10px 16px;
}

.helper-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.helper-message {
    background: #FAFAFA;
    ;
    border-radius: 4px;
    padding: 1px 4px;
    font-size: 14px;
    display: flex;
    align-items: center;

}

.icon {
    align-self: baseline;
    margin-bottom: 3px;
    font-size: 20px;
    color: #c2c2c2;

}

.preview-sections-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    height: 100%;
}

.preview-btns {
    align-self: center
}

.confirm-wrapper {
    :global(.ant-checkbox) {
        align-self: flex-start;
        margin-top: 6px;
    }

}

.preview-iframe-modal {
    top: 50px !important;
    background-color: #fff !important;
    padding: 35px;
    :global(.ant-modal-body) {
        height: calc(100vh - 100px);
        padding: 0 !important;
        background-color: #fff !important;
    }
    :global(.ant-modal-content) {
        background-color: #fff !important;
    }
    :global(.ant-modal-close) {
        top: -31px !important;
        inset-inline-end: -25px !important;
    
    }
}