.dashboardContainer {
    a {
        color: #000000;
    }

    .filterText {
        cursor: pointer;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .datesContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        .dates {
            background: #fff;
            border-radius: 8px;
            font-weight: 600;
            padding: 8px;
            .dateSpan {
                text-decoration: underline;
                font-weight: 700;
            }
        }
    }


    .headerContainer {
        .headerBg {
            background: #FFFFFF;
            border-radius: 10px;
            // padding: 15px 20px;
        }
    }

    .taskContainer {
        // background: #FFFFFF;
        // border-radius: 10px;
        // padding: 15px 20px;
        // box-shadow: 0px 4px 4px 0px #0000000A;
        height: 100%;
        h1 {
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            color: #464255;
            margin: 0px;
        }

        .taskList {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            gap: 20px;
            margin-top: 1rem;
            svg{
                align-self: center;
                justify-self: flex-end;
            
            }

            .taskListItem {
                padding: 5px 10px;

                h2 {
                    margin: 0px;
                    font-size: 40px;
                    font-weight: 700;
                    text-align: left;
                    color: #464255;
                }
                h3 {
                    margin: 0px;
                    font-size: 40px;
                    font-weight: 400;
                    text-align: left;
                    color: #000000;
                }
                p {
                    font-size: 14px;
                    font-weight: 500;
                    text-align: left;
                    color: #000000;
                    margin: 0px;
                }

                .taskListName {
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
                    p {
                        min-width: 75px;
                    }
                }

                .line {
                    margin-top: 5px;
                    width: 60px;
                    height: 3px;
                    border-radius: 6px 0px 0px 0px;
                }

                .time {
                    background: #00C6E9;
                }

                .due {
                    background: #e39322;
                }

                .early {
                    background: #FFDB5B;

                }

                .late {
                    background: #9F5050;

                }
                .orderLine{
                   background: #000000;
                }
                .firstLine{
                    background: #FFB326;
                }
            }
        }
    }

    .stageContainer {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 15px 20px;
        box-shadow: 0px 4px 4px 0px #0000000A;
        height: 100%;

        h1 {
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            color: #464255;
        }

        .patientsProgress {
            margin-top: 1rem;

            .patientProgress {
                span {

                    font-size: 20px;
                    font-weight: 700;

                    text-align: left;

                }

                p {
                    // min-width: 200px;
                    font-size: 16px;
                    color: #000000;
                    text-align: left;
                    margin: 0px;

                }

            }

            .line {
                height: 2px;
                width: 95%;
                background: #F3F2F7;
                margin: 1rem 0px;
                margin-left: auto;
                display: block;
            }
        }
    }

    .headerCount {
        display: flex;
        align-items: center;
        gap: 15px;

        h1 {
            color: #464255;
            font-size: 44px;
            font-weight: 700;
            margin: 0px;
            text-align: left;
        }

        p {
            text-align: left;
            font-size: 15px;
            font-weight: 400;
        }
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .applyBtn {
        width: 6rem;
        display: block;
    }
}

.customProgress{
    :global(.ant-progress-bg) {
        height: 18px !important;
    }
    :global(.ant-progress-inner) {
        background: #fff !important;
    }
}

:global {
    // .ant-progress-bg {
    //     height: 18px !important;
    // }

    .ant-progress {
        display: flex !important;
    }

    .ant-card {
        border-radius: 10px !important;
    }

    // .ant-progress-inner {
        // background: #fff !important;
    // }

    .ant-card {
        border: 1px solid #fff !important;
    }
}
