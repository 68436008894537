.option-grid {
  display: grid;
  grid-template-columns: 2fr 7fr 1fr;
}

.option-label {
  font-weight: bold;
  text-transform: capitalize;
  justify-self: flex-start;
}

.option-description {
  color: #798c96;
  font-weight: 500;
}

.option-checkmark {
  justify-self: flex-end;
  color: #00c6e9;
}
