.profile-container {
    margin: 20px 20px;
    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }
    .footer-btn{
        width: 10rem;
        height: 2.5rem;
        }

}


