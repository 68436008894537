.patient-details-radio-buttons {
  color: #77849e !important;
  width: 10rem !important;
  height: 90% !important;
  padding-top: 0.1rem !important;
}

// .site-collapse-custom-collapse .site-collapse-custom-panel {
//   margin-bottom: 24px !important;
//   overflow: hidden !important;
//   //   background: #e9f0f4;
//   border: 0px !important;
//   border-radius: 2px !important;
// }

// .site-collapse-custom-panel {
//   margin-bottom: 1rem !important;
// }

// .site-collapse-custom-collapse {
//   margin-bottom: 1rem !important;
// }

[data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  //   overflow: hidden;
  background: white;
  //   border: 0px;
  border-radius: 6px !important;
  border-style: none !important;
}

.site-collapse-custom-collapse {
  background-color: #e9f0f4 !important;
  border-style: none !important;

  border-radius: 17px !important;
}

.upload-file-scan-container {
  background: white !important;
  height: 400px;
  padding: 15px;
}


.float-chat-btn{
  .ant-float-btn-icon{
    display: flex;
    justify-content: center;
    width: 40px;
  
  }
}