.frame-container {
    // height: 300px;
    margin: 20px 10px;
    // color: #fff;
    // padding: 1rem 0.5rem;   
}

@media only screen and (max-width: 365px) {
    .frame-container{
        margin: 20px 2px;
    }
    .teethChart-container {
        padding: 0px 0px 20px 0px;
    }
    // .teethChart-container .teethRow-container .teethRow-one .teeth-structure  {
    //  background: red;
    // }

    .ipr-detail-container .teethChart-container .teethRow-container .teethRow-one svg {
        width: 20px;
        height: 45px;
    }

    .teethChart-container .teethRow-container .teethRow-one .teeth-structure span {
        font-size: 6px;
        top: 43%;
    }

    .teethChart-container .teethRow-container .teethRow-bgOne {
        padding: 0px;
        height: 25px;
    }

    .teethChart-container .teethRow-container .teethRow-bgTwo {
        padding: 0px;
        height: 25px;
        bottom: 1px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides {
        top: 40px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides h2 {
        font-size: 10px;
        font-weight: 500;
    }

    .teethChart-container .teethRow-container {
        margin-top: 0px !important;
    }

    .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
        width: 16.84px;
        height: 17.58px;
        border-radius: 14.21px;
        font-size: 9px;
        left: -1px;
        &::after{
            content: ""; 
            position: absolute;
            top: 50%;
            left: 100%;
            width: calc(100vw - 60px); 
            height: 1px; 
            background-color: #dddddd;
            // background-color: #0133e7dd;
            transform: translateY(-50%); 
            z-index: 1; 
        }
        
    }
    .ipr-detail-container .aligners-container .aligner-circle {
        width: 16.84px;
        height: 17.58px;
        font-size: 9px;
        margin-bottom: 0px;
        z-index: 15;
        position: relative;
        line-height: 18px;
        
    }
    .aligners-container .aligner-0-upper::before {
        width: 15px;
        height: 13px;
        bottom: -11px;
        left: 16%;

    }
    .aligners-container .aligner-0-lower::before {
        width: 12px;
        height: 29px;
    }
    .aligner-scroll-container {
        padding-top: 12px !important;
    }
}

@media only screen and (min-width: 366px) and (max-width: 375px) {
    .teethChart-container {
        padding: 0px 0px 20px 0px;
    }

    .ipr-detail-container .teethChart-container .teethRow-container .teethRow-one svg {
        width: 21px;
        height: 45px;
    }

    .teethChart-container .teethRow-container .teethRow-one .teeth-structure span {
        font-size: 6px;
        top: 43%;
    }

    .teethChart-container .teethRow-container .teethRow-bgOne {
        padding: 0px;
        height: 25px;
    }

    .teethChart-container .teethRow-container .teethRow-bgTwo {
        padding: 0px;
        height: 25px;
        bottom: 1px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides {
        top: 40px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides h2 {
        font-size: 10px;
        font-weight: 500;
    }

    .teethChart-container .teethRow-container {
        margin-top: 0px !important;
    }

    .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
        width: 17.84px;
        height: 18.58px;
        border-radius: 14.21px;
        font-size: 9px;
        left: -1px;
        &::after{
            content: ""; 
            position: absolute;
            top: 50%;
            left: 100%;
            width: calc(100vw - 60px); 
            height: 1px; 
            background-color: #dddddd;
            transform: translateY(-50%); 
            z-index: 1; 
        }
        
    }
    .ipr-detail-container .aligners-container .aligner-circle {
        width: 17.84px;
        height: 18.58px;
        font-size: 9px;
        margin-bottom: 0px;
        z-index: 15;
        position: relative;
        line-height: 18px;
        
    }
    .aligners-container .aligner-0-upper::before {
        width: 15px;
        height: 13px;
        bottom: -11px;
        left: 27%;

    }
    .aligners-container .aligner-0-lower::before {
        width: 12px;
        height: 29px;
    }
    .aligner-scroll-container {
        padding-top: 12px !important;
    }
}

@media only screen and (min-width: 376px) and (max-width: 400px) {
    .teethChart-container {
        padding: 0px 0px 20px 0px;
    }

    .ipr-detail-container .teethChart-container .teethRow-container .teethRow-one svg {
        width: 22px;
        height: 45px;
    }

    .teethChart-container .teethRow-container .teethRow-one .teeth-structure span {
        font-size: 9px;
        top: 43%;
    }

    .teethChart-container .teethRow-container .teethRow-bgOne {
        padding: 0px;
        height: 25px;
    }

    .teethChart-container .teethRow-container .teethRow-bgTwo {
        padding: 0px;
        height: 25px;
        bottom: 1px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides {
        top: 40px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides h2 {
        font-size: 10px;
        font-weight: 500;
    }

    .teethChart-container .teethRow-container {
        margin-top: 0px !important;
    }

    .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
        width: 18.84px;
        height: 19.58px;
        border-radius: 14.21px;
        font-size: 9px;
        left: -1px;
        &::after{
            content: ""; 
            position: absolute;
            top: 50%;
            left: 100%;
            width: calc(100vw - 50px); 
            height: 1px; 
            background-color: #dddddd; 
            transform: translateY(-50%); 
            z-index: 1; 
        }
        
    }
    .ipr-detail-container .aligners-container .aligner-circle {
        width: 18.84px;
        height: 19.58px;
        font-size: 9px;
        margin-bottom: 0px;
        z-index: 15;
        position: relative;
        line-height: 20px;
        
    }
    .aligners-container .aligner-0-upper::before {
        width: 15px;
        height: 13px;
        bottom: -11px;
        left: 27%;

    }
    .aligners-container .aligner-0-lower::before {
        width: 12px;
        height: 29px;
    }
    .aligner-scroll-container {
        padding-top: 12px !important;
    }
}

@media only screen and (min-width: 401px) and (max-width: 435px) {
    .teethChart-container {
        padding: 0px 0px 20px 0px;
    }

    .ipr-detail-container .teethChart-container .teethRow-container .teethRow-one svg {
        width: 23px;
        height: 45px;
    }

    .teethChart-container .teethRow-container .teethRow-one .teeth-structure span {
        font-size: 9px;
        top: 43%;
    }

    .teethChart-container .teethRow-container .teethRow-bgOne {
        padding: 0px;
        height: 25px;
    }

    .teethChart-container .teethRow-container .teethRow-bgTwo {
        padding: 0px;
        height: 25px;
        bottom: 1px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides {
        top: 40px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides h2 {
        font-size: 10px;
        font-weight: 500;
    }

    .teethChart-container .teethRow-container {
        margin-top: 0px !important;
    }

    .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
        width: 18.84px;
        height: 19.58px;
        border-radius: 14.21px;
        font-size: 9px;
        left: -1px;
        &::after{
            content: ""; 
            position: absolute;
            top: 50%;
            left: 100%;
            width: calc(100vw - 50px); 
            height: 1px; 
            background-color: #dddddd; 
            transform: translateY(-50%); 
            z-index: 1; 
        }
        
    }
    .ipr-detail-container .aligners-container .aligner-circle {
        width: 18.84px;
        height: 19.58px;
        font-size: 9px;
        margin-bottom: 0px;
        z-index: 15;
        position: relative;
        line-height: 20px;
        
    }
    .aligners-container .aligner-0-upper::before {
        width: 15px;
        height: 13px;
        bottom: -11px;
        left: 27%;

    }
    .aligners-container .aligner-0-lower::before {
        width: 12px;
        height: 29px;
    }
    .aligner-scroll-container {
        padding-top: 12px !important;
    }
}


@media only screen and (min-width: 436px) and (max-width: 768px) {
    .teethChart-container {
        padding: 0px 0px 20px 0px;
    }

    .ipr-detail-container .teethChart-container .teethRow-container .teethRow-one svg {
        width: 23px;
        height: 45px;
    }

    .teethChart-container .teethRow-container .teethRow-one .teeth-structure span {
        font-size: 9px;
        top: 43%;
    }

    .teethChart-container .teethRow-container .teethRow-bgOne {
        padding: 0px;
        height: 25px;
    }

    .teethChart-container .teethRow-container .teethRow-bgTwo {
        padding: 0px;
        height: 25px;
        bottom: 1px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides {
        top: 40px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides h2 {
        font-size: 10px;
        font-weight: 500;
    }

    .teethChart-container .teethRow-container {
        margin-top: 0px !important;
    }

    .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
        width: 18.84px;
        height: 19.58px;
        border-radius: 14.21px;
        font-size: 9px;
        left: -1px;
        &::after{
            content: ""; 
            position: absolute;
            top: 50%;
            left: 100%;
            width: calc(100vw - 50px); 
            height: 1px; 
            background-color: #dddddd; 
            transform: translateY(-50%); 
            z-index: 1; 
        }
        
    }
    .ipr-detail-container .aligners-container .aligner-circle {
        width: 18.84px;
        height: 19.58px;
        font-size: 9px;
        margin-bottom: 0px;
        z-index: 15;
        position: relative;
        line-height: 20px;
        
    }
    .aligners-container .aligner-0-upper::before {
        width: 15px;
        height: 13px;
        bottom: -11px;
        left: 27%;

    }
    .aligners-container .aligner-0-lower::before {
        width: 12px;
        height: 29px;
    }
    .aligner-scroll-container {
        padding-top: 12px !important;
    }
}

@media only screen and (min-width: 770px) and (max-width: 853px) {
    .teethChart-container {
        padding: 0px 0px 20px 0px;
    }

    .ipr-detail-container .teethChart-container .teethRow-container .teethRow-one svg {
        width: 47px;
        height: 60px;
    }

    .teethChart-container .teethRow-container .teethRow-one .teeth-structure span {
        font-size: 11px;
        top: 43%;
    }

    .teethChart-container .teethRow-container .teethRow-bgOne {
        padding: 0px;
        height: 25px;
    }

    .teethChart-container .teethRow-container .teethRow-bgTwo {
        padding: 0px;
        height: 25px;
        bottom: 1px;
    }

    // .teethChart-container .teethRow-container .teethRow-one .tooth-sides {
    //     top: 36px;
    // }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides h2 {
        font-size: 12px;
        font-weight: 500;
    }

    // .teethChart-container .teethRow-container {
    //     margin-top: 0px !important;
    // }

    .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
        width: 22.84px;
        height: 23.58px;
        border-radius: 14.21px;
        font-size: 10px;
        left: -1px;
        &::after{
            content: ""; 
            position: absolute;
            top: 50%;
            left: 100%;
            width: calc(100vw - 50px); 
            height: 1px; 
            background-color: #dddddd; 
            transform: translateY(-50%); 
            z-index: 1; 
        }
        
    }
    .ipr-detail-container .aligners-container .aligner-circle {
        width: 22.84px;
        height: 23.58px;
        font-size: 10px;
        margin-bottom: 0px;
        z-index: 15;
        position: relative;
        line-height: 24px;
        
    }
    .aligners-container .aligner-0-upper::before {
        width: 30px;
        height: 19px;
        bottom: -18px;
        left: 17%;

    }
    .aligners-container .aligner-0-lower::before {
        width: 17px;
        height: 30px;
        left: 17%;
    }
    .aligner-scroll-container {
        padding-top: 12px !important;
    }
}


@media only screen and (min-width: 855px) and (max-width: 1025px) {
    .teethChart-container {
        padding: 0px 0px 20px 0px;
    }

    .ipr-detail-container .teethChart-container .teethRow-container .teethRow-one svg {
        width: 54px;
        height: 75px;
    }

    .teethChart-container .teethRow-container .teethRow-one .teeth-structure span {
        font-size: 13px;
        top: 43%;
    }

    .teethChart-container .teethRow-container .teethRow-bgOne {
        padding: 0px;
        height: 40px;
    }

    .teethChart-container .teethRow-container .teethRow-bgTwo {
        padding: 0px;
        height: 40px;
        bottom: 1px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides {
        top: 66px;
    }

    .teethChart-container .teethRow-container .teethRow-one .tooth-sides h2 {
        font-size: 16px;
        font-weight: 500;
    }

    // .teethChart-container .teethRow-container {
    //     margin-top: 0px !important;
    // }

    .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
        width: 30.84px;
        height: 31.58px;
        border-radius: 14.21px;
        font-size: 11px;
        left: -1px;
        &::after{
            content: ""; 
            position: absolute;
            top: 50%;
            left: 100%;
            width: calc(100vw - 50px); 
            height: 1px; 
            background-color: #dddddd; 
            transform: translateY(-50%); 
            z-index: 1; 
        }
        
    }
    .ipr-detail-container .aligners-container .aligner-circle {
        width: 30.84px;
        height: 31.58px;
        font-size: 10px;
        margin-bottom: 0px;
        z-index: 15;
        position: relative;
        line-height: 29px;
        
    }
    .aligners-container .aligner-0-upper::before {
        width: 30px;
        height: 19px;
        bottom: -18px;
        left: 27%;

    }
    .aligners-container .aligner-0-lower::before {
        width: 17px;
        height: 30px;
        // left: 17%;
    }
    .aligner-scroll-container {
        padding-top: 12px !important;
    }
}