@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");

.ant-table-thead {
  font-size: 14px;
  font-family: Mulish, sans-serif;
  font-weight: 600;
  color: #2f3a4c !important;
}
.ant-table-filter-column-title {
  padding-right: 0em !important;
}
.headerListPatient {
  display: flex;
  align-items: center;
  gap: 10px;
  h3{
  margin: 0px !important;
  }
}
.patientListLength {
  padding: 3px;
  background: #ddfaff;
  border-radius: 15px;
  color: #05a2bd;
}

.smileAlign-patientListLength{
  padding: 3px;
  background: #f0f0f0;
  border-radius: 15px;
  color: #DE8754;
}


.color-filter-wrapper{
  // display: flex;
  .color-tag{
    padding: 8px;
    border-radius: 50%;
    margin-bottom: -4px;
    margin-left: 6px;
  }
}

.appTable-column-title{
  margin: 0px;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;
  }
  .patient-name-tableData{
    p{
      margin: 0px;
      
    }
  }