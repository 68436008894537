@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");

.steps {
  width: 100%;
  padding-bottom: 3rem;
  .created-date{
    width: 135px;
    padding-left: 18px;
    font-weight: 800;
    color: #757575;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    margin: 0px;
  }
  .ant-steps-item-active {
    .ant-steps-item-title {
      // border-bottom: solid !important;
      // border-bottom-color: #00c6e9 !important;
      font-weight: 700;
      color: rgba(34, 34, 34, 0.72) !important;
    }
  }
  .ant-steps-item-title {
    white-space: nowrap;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
  }
  .shipped-url {
    display: flex;
    width: 250px;
    word-break: break-all;
    align-items: center;
    text-align: left;
  }
  .shipping-label {
    margin-bottom: 0px;
    &:hover {
      .editIcon {
        display: inline;
      }
    }
  }
  .tracking-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 215px;
    word-break: break-all;
    text-align: left;
    padding: 10px;
    border: 1px solid #555e6d;
    border-radius: 32px;
    margin-left: -2rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .tracking-url a {
    display: flex;
    align-items: center;
    color: #000;
    width: 100%;
    text-decoration: none;
    overflow: hidden;
  }

  .tracking-url .tracking-link-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-right: 10px;
    width: 165px;
  }
  .edit-btn {
    border-radius: 5rem;
    font-family: "Mulish", sans-serif;
    height: 38px;
    display: flex;
    flex-direction: row-reverse;
    font-weight: 600;
    margin-left: -1.4rem;
    align-items: center;
    gap: 10px;
  }
  .editIcon {
    width: 14px;
    display: none;
    position: absolute;
    top: -20px;
    cursor: pointer;
  }
  .ant-steps-item-tail::after{ 
    height: 2px !important;
  }
}

.order-statuses {
  font-weight: bolder !important;
  font-size: 15px !important;
  text-transform: capitalize;
  color: rgba(34, 34, 34, 0.72) !important;
}

.order-statuses-order-description {
  white-space: pre-line;
}

.order-statuses-note {
  font-size: 13px !important;
  text-transform: capitalize;
  color: rgba(34, 34, 34, 0.72) !important;
  font-weight: bold;
}

.order-status-header {
  font-size: 14px !important;

  color: #77888b !important;
}

.order-status-col {
  border-right: 1px solid lightgray;
  margin-right: 2rem !important;

  @media screen and (max-width: 1000px) {
    border-right: none !important;
    margin-right: 0 !important; // Adjust if needed for mobile layout
  }
}

.order-container {
  .ant-steps-item-title {
    font-weight: 700 !important;
    color: rgba(34, 34, 34, 0.72) !important;
    @media screen and (max-width: 1000px) {
      width: 100% !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;

    }
  }
}

.order-status-container {
  background: #fafafa;
  border: 1px dashed #d7d6d6;
  box-sizing: border-box;
  border-radius: 13px 13px 0px 0px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  flex-wrap: nowrap !important;
  .scan-file-display {
    position: relative;
    width: 100%;
    height: 134px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
    background: #FAFAFA;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .scan-file-icon{
        svg{
            width: 40px;
            height: 40px;
            margin-top: -20px;
        }
  
    }

    .fileName {
        color: #404040;
        font-size: 12px;
        font-weight: 400;
        margin: 0px;
        font-size: 13px;
        width: 100%;
        display: block;
        text-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 125px;
        position: absolute;
       bottom: 10px;
       left: 10px;
    }
    .fileMask {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
   
        opacity: 0;
        transition: opacity 0.3s;
        // width: 131px;
        // height: 131px;
        border-radius: 8px;

        span {
            padding-left: 3px;
        }
        .downIcon{
        font-size: 20px;
        margin-right: 1rem;
        cursor: pointer;
        }
        .viewIcon{
            font-size: 20px;
            margin-right: 1rem;
            cursor: pointer;
        }
    }

    &:hover {
        .fileMask {
            opacity: 1;
        }
    }
}
}
.order-container {
  text-align: left !important;
  width: 100% !important;
  border-radius: 8px !important;
  border: none !important;
  .ant-card-body{
    padding: 0px !important;
    border: 1px dashed #d7d6d6;
    border-radius: 13px;
  }
 
}

.delivery-modal-container {
  .uploader-file-list {
    display: flex;
    gap: 10px;
  }

  .uploaded-files {
    display: flex;
    gap: 5px;
    // overflow-x: scroll;

    p {
      max-width: 130px;
      min-width: 130px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .resources-container {
    margin-top: 1rem;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    a {
      max-width: 200px;
    }

    .resource {
      a {
        color: #555e6d;
        max-width: 25%;
      }

      p {
        max-width: 150px;
        min-width: 150px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .resource {
    position: relative;

    .resource-wrapper {
      cursor: pointer;
      visibility: hidden;
      position: absolute;
      width: 131px;
      height: 131px;
      background-color: rgba(0, 0, 0, 0.35);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      svg {
        color: #fff;
      }
    }
  }

  .resource:hover {
    .resource-wrapper {
      visibility: visible;
    }
  }

  .ant-upload-list {
    flex-direction: row-reverse !important;
    /* align-items: baseline; */
    justify-content: flex-end !important;
  }
}




