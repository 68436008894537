.btn {
  border-radius: 30px;
  min-width: 4.5rem;
}

.footerContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.headerContainer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.manual-uploader {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 70px;
}

.generate-tp-link__wrapper {
  text-align: end;
  margin-right: -15px;
  // margin-top: -20px;
}

.custom-antd-clear-btn {
  background: rgb(189 191 191 / 41%);
  color: #000;
  padding: 3px;
  font-size: 10px;

  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  height: 72%;

  &:hover {
    cursor: pointer;
  }
}

.generated-link {

  :global(.ant-input) {
    // background-color: antiquewhite !important;
    background-color: rgb(189 191 191 / 41%) !important;
    color: #000 !important;
    padding: 0 3px !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  :global(.ant-input-clear-icon) {
    display: none;
  }

  :global(.ant-input-suffix) {
    margin-inline-start: 0px;
  }

  
}