
.invoice-table{
   color:#606A6B;
   font-family: Mulish;
}
.ant-table{
    font-size: 15px;
}
.invoice-table .ant-table-thead > tr > th {
    color:#2F3A4C;
    font-weight: 600;
    font-family: Mulish;
    font-size: 14px;
}
.cancel-button{
    border-radius: 44px;
    border: 1px solid #00C6E9;
    color:#00C6E9;
}
.paymentStatus-modal .ant-modal-body{
    max-height: 700px;
}

.invoice-list-container{
    .ant-radio-button-wrapper{
        min-width: 150px;
    }
.radio-row{
  padding-left: 20px;
  margin-top: 10px;
 
  .download-btn
  {
    font-weight: 600;
    cursor: pointer;
  }
}
}
.first-table-container{
    margin: 1rem 0px 0px 1rem;
}