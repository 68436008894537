.treatment-rows-container {
  width: 100%;
}

.treatment-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
