@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");

.notifications-wrapper{
  margin-left: 150px;
}

.notification-header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
  .notification-wrapper{
    display: flex;
    align-items: flex-start;
    grid-gap: 3px;
    gap: 0px;
    align-items: center;
  }
  .ant-page-header{
    padding-right: 0;
  }
}
.notification-counter {
  // position: absolute;
  // top: 23px;
  // right: 5px;
  background: #ddfaff;
  padding: 3px;
  border-radius: 15px;
  color: #05a2bd;
  margin-bottom: 0;
  
}
.notifications-container {
  background-color: white;
  text-align: left;
}

.lab-notification-filter{
  .clinic-filter{
    .dropdown-icon{
      margin-left: 5px;
    }
  }
}
.clinic-list-popover{
  .ant-popover-inner-content{
    padding: 0;
  }
}
.clinic-filter-actions{
  align-items: center;
  padding: 0 5px;
}

.notifications-text {
  margin-left: 35px;
  text-align: left;
  // width: 70%;
  margin-top: -15px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
}
.notifications-text-read {
  margin-left: 50px;
  text-align: left;
  width: 70%;
  margin-top: -15px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #a7acad;
}
.notifications-timestamp {
  width: 10%;
  // margin-top: -25px;
  float: right;
}

.notifications-timestamp-read {
  width: 10%;
  // margin-top: -25px;
  float: right;
  color: #a7acad;
}
.border-line{
  background: #D9D9D9;;
  width: 100%;
  height: 1px;
  margin: 25px 0px 5px 0px;
}
.assignee-row{
  display: flex;
  align-items: center;
  gap: 15px;
}
.time-stamp{
color: #77888B;
min-width: 60px;
}

.notifications-status {
  background-color: #38c976;
  width: 15px;
  height: 12px;
  border-radius: 50%;
}

.notifications-status-read {
  background-color: #bbbbbb;
  height: 10px;
  width: 10px;
  border-radius: 15px;
}

.button-notifications {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .notifications-wrapper{
    margin-left: 0px;
  }
}