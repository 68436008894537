.resources-container {}

.addResource-form {
    .addOn-btn {
        display: flex;
        align-items: center;
        border: none;
        color: #828282;
        font-weight: 400;
        box-shadow: none;
        margin-right: auto;
        padding: 0px;
        justify-content: flex-start;

        svg {
            margin-right: 0px;
        }
    }

    .delete-btn {
        border: none !important;
        box-shadow: none !important;
        background: none;

        svg {
            path {
                fill: rgb(0, 0, 0) !important;
            }
        }

        &:hover,
        &:active {
            svg {
                path {
                    fill: red !important;
                }
            }
        }
    }
}

.clinics-sharedList {
    border-radius: 4px;
    background: #fff;
    margin-top: 30px;
    padding: 5px;
    border: 1px solid #d9d9d9;

    .shared-clinic {
        display: flex;
        height: 36px;
        align-items: center;
        // margin-top: 10px;
        padding: 0px 5px;
        font-weight: 600;
        &:hover {
            background: #E6FFFF;
        }
    }
}

.resources-list-container {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.02);
    min-height: 80vh;
    margin-top: 1rem;


    .ant-table-cell {
        p {
            margin-bottom: 0px;
        }
    }

    .share-tab {
        border-radius: 17px;
        background: #77888B;
        color: #fff;
        padding: 5px 15px;
        display: inline-block;
        margin-right: 10px;
    }

    // .resource-headerRow {
    //     background: #FAFAFA;
    //     height: 60px;
    //     border-radius: 8px;

    //     h1 {
    //         color: #555E6D;
    //         font-family: "Mulish", sans-serif;
    //         font-size: 17px;
    //         font-weight: 800;
    //         margin: 0;
    //         text-align: center;
    //     }

    //     .ant-col {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //     }
    // }

    .empty-resources-list {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70vh;
    }

    // .resources-list {
    //     .resource-list {
    //         padding: 20px 0;

    //         .share-tab {
    //             border-radius: 17px;
    //             background: #77888B;
    //             color: #fff;
    //             padding: 7px 9px;
    //         }

    //         p {
    //             color: rgba(34, 34, 34, 0.72);
    //             font-family: "Mulish", sans-serif;
    //             font-size: 14px;
    //             font-weight: 700;
    //             margin: 0px;
    //         }

    //         &:hover {
    //             background: rgba(111, 111, 111, 0.05);
    //         }
    //     }

    // }
}

.resource-detail-container {
    position: relative;

    .resource-editBtn {
        position: absolute;
        right: 15px;
        top: 0px;
    }
    .resource-detail {
        margin-bottom: 20px;
        .resources-container {
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 10px;
            a {
                max-width: 200px;
            }
            .resource {
                //  margin-right: 1rem;
                a {
                    color: #555E6D;
                    max-width: 25%;
                }
            }
        }
        label {
            color: #555E6D;
            font-family: "Mulish", sans-serif;
            font-size: 17px;
            font-weight: 800;
        }
        p {
            color: rgba(34, 34, 34, 0.72);
            font-family: "Mulish", sans-serif;
            font-size: 16px;
            font-weight: 500;
        }
        p {
        font-size: 14px;
        
        }
    }

    // .resource-detail {
    //     margin-bottom: 20px;

       

    //     label {
    //         color: #555E6D;
    //         font-family: "Mulish", sans-serif;
    //         font-size: 17px;
    //         font-weight: 800;
    //     }

    //     p {
    //         color: rgba(34, 34, 34, 0.72);
    //         font-family: "Mulish", sans-serif;
    //         font-size: 16px;
    //         font-weight: 500;
    //     }

    // }

}

.addResourceModal {
    .ant-select-selection-item {}
}