.progress-image-card {
  width: "100%";
  text-align: left;
  height: 100%;
  .empty-plan-component{
    margin: 8px !important;
  }
    .preview-content {
      overflow-y: auto;
  
      h1 {
        font-family: "Mulish", sans-serif;
        font-size: 16px;
        font-weight: 700;
        margin: 0px 0px 10px 0px;
      }
  
      .image-name {
        font-family: "Mulish", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #8E9596;
        margin-bottom: 5px !important;
  
      }
    }
  .upload-text{
    display: flex;
    align-items: center;
    flex-direction: column;
    svg{
      // transform: rotate(180deg);
      
    }
  }

  .image-upload-container {
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    // max-height: 52.3rem;
    // min-height: 52.3rem;
    .image-Card {
      // padding: 7px;
      // box-shadow: #3c40434d 0px 1px 2px 0px, #3c404326 0px 1px 3px 1px;
      // border: 0.5px solid #00c6ec78;
      border-radius: 5px;
      height: 100%;
      max-height: 266px;
      .image-header{
        margin-bottom: 10px;
      }
      .image-type{
        margin: 0px;
      }
      .ant-upload-wrapper {
        display: flex !important;
        justify-content: center !important;
      }
      .ant-image {
        margin: 0 auto !important;
        display: flex;
      }
      .del-icon{
        color: red;
        margin-left: 0.5rem;
        margin-top: 0.2rem;
      }
    }
    .smileAlign-imageCard {
      border: 0.5px solid #DE8754;
    }
    .uploadImage .ant-upload.ant-upload-select {
      min-width: 210px;
      min-height: 175px;
      width: 100%;
    }
    .ant-image {
      width: 100% !important;
    }
    .ant-image-img {
      height: 175px !important;
      background-color: rgba(0, 0, 0, 0.02);
      border: 1px dashed #d9d9d9;
      padding: 7px;
    }
  
    .image-dim {
      min-width: 210px;
      min-height: 150px;
      object-fit: cover;
      object-position: center center;
      @media only screen and (min-width: 1200px) and (max-width: 1900px) {
        min-width: 210px;
        min-height: 150px;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
  &.impression-image-card {
    .ant-card-extra {
      padding: 0 !important;
    }
  }

  &.no-impression-list {
    .ant-card-body {
      padding: 0;
    }
  }
  .approve-btn{
    margin-right: 10px;
    border-radius: 5rem;
    font-family: "Mulish", sans-serif;
  }
  .approve-text{
    background: #E4F5EB;
    border-radius: 34px;
    color: green;
    padding: 0.4rem;
    width: 7rem;
    text-align: center;
    margin: 0;
  }
}

.impressions-image-card {
  .ant-image {
    width: 100%;
  }
  .image-impressions {
    object-fit: contain;
  }
  .impression-approve-container {
    display: flex;
    justify-content: flex-end;
    // flex-direction: column;
    margin-top: 15px;
    .reject-input {
      margin-top: 10px;
    }
    .impression-button {
      border-radius: 5rem;
      font-family: "Mulish", sans-serif;
      margin-right: 10px;
    }
    .impression-approved-text {
      background: #e4f5eb;
      border-radius: 34px;
      color: green;
      padding: 0.4rem;
      width: 7rem;
      text-align: center;
      margin: 0;
    }
    .reject-btn{
      margin-right: 10px;
      border-radius: 5rem;
      font-family: "Mulish", sans-serif;
    }
  }
}
.progress-images-container {
  height: 30rem;
  min-height: 30rem;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  .image-Card {
    // padding: 7px;
    // box-shadow: #3c40434d 0px 1px 2px 0px, #3c404326 0px 1px 3px 1px;
    // border: 0.5px solid #00c6ec78;
    border-radius: 5px;
    height: 100%;
    max-height: 266px;
    .image-type{

    }
    .ant-upload-wrapper {
      display: flex !important;
      justify-content: center !important;
    }
    .ant-image {
      margin: 0 auto !important;
      display: flex;
    }
  }
  .smileAlign-imageCard {
    border: 0.5px solid #DE8754;
  }
}

.progress-drd-item{
  display: flex;
  justify-content: space-between;
}