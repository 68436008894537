.scans-col-container {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    .scans-col-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
         padding: 14px 14px 0 14px;

        .scans-col-headings {

            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                font-family: "Mulish", sans-serif;
                color: #000;
                font-size: 16px;
                font-weight: 700;
                text-align: left;
                margin: 0px;
                  text-transform: capitalize;
            }

            h2 {
                color: #343434;
                font-family: "Mulish", sans-serif;
                font-size: 15px;
                font-weight: 700;
                padding-left: 10px;
                margin: 0px;

                span {
                    color: #77888B;
                    font-family: "Mulish", sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                }
            }


        }
        .new-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
        }
    }

    .history-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;

        span {
            color: #000;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 7px;

            svg {
                font-size: 26px;
            }
        }
    }
}


.scan-history-container {
    .scan-history-header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        h2 {
            margin: 0;
            font-family: "Mulish", sans-serif;
            font-size: 16px;
            font-weight: 700;
            text-transform: capitalize;
        }

        p {
            color: #343434;
            font-family: "Mulish", sans-serif;
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            text-transform: capitalize;
            span {
                color: #77888B;
                font-family: "Mulish", sans-serif;
                font-size: 14px;
                font-weight: 400;
                padding-left: 2px;
            }
        }

    }
    .scan-file-name{
        color: #8F8F8F;
        font-family: "Mulish", sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
        margin: 5px 0px !important;
            }
    .scan-file-display {
        position: relative;
        width: 100%;
        height: 134px;
        border-radius: 6px;
        border: 1px solid #E1E1E1;
        background: #FAFAFA;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .scan-file-icon{
            svg{
                width: 40px;
                height: 40px;
                margin-top: -20px;
            }
      
        }
  
        .fileName {
            color: #404040;
            font-size: 12px;
            font-weight: 400;
            margin: 0px;
            font-size: 13px;
            width: 100%;
            display: block;
            text-wrap: nowrap;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 125px;
            position: absolute;
           bottom: 10px;
           left: 10px;
        }
        .fileMask {
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
       
            opacity: 0;
            transition: opacity 0.3s;
            // width: 131px;
            // height: 131px;
            border-radius: 8px;

            span {
                padding-left: 3px;
            }
            .downIcon{
            font-size: 20px;
            margin-right: 1rem;
            cursor: pointer;
            }
            .viewIcon{
                font-size: 20px;
                margin-right: 1rem;
                cursor: pointer;
            }
        }

        &:hover {
            .fileMask {
                opacity: 1;
            }
        }
    }
}