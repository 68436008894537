.file-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
}

.file-name {
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 16px;
  margin-top: 4px;
  max-width: 180px;
}

.no-margin {
  p {
    margin: 0;
  }
}
