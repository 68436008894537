.teethChart-container {
    background: #fff;
    border-radius: 8px;
    padding: 0px 16px 16px 16px;

    .ant-collapse {
        background: #fff;
    }

    .ant-collapse-header {
        padding: 10px 10px !important;
    }

    .ant-collapse-expand-icon {
        margin-top: 7px !important;
    }


    .ant-divider {
        margin: 5px 0 !important;
    }

    .chart-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-form-item {
            margin-bottom: 0px !important;
        }

        h1 {
            margin: 0;
            color: #000;
            font-family: Mulish;
            font-size: 15px;
            font-weight: 700;
        }

        .header-select {
            width: 154px;
            text-align: left;
        }

    }

    .teethRow-container {
        position: relative;

        .teethRow-bgOne {
            position: absolute;
            top: 3px;
            border-radius: 30px;
            background: #FFD4D4;
            margin: 0px;
            padding: 0px 20px 0px 10px;
            height: 32px;
            width: 100%;
        }

        .teethRow-bgTwo {
            position: absolute;
            bottom: 5px;
            border-radius: 30px;
            background: #FFD4D4;
            width: 100%;
            margin: 0px;
            padding: 0px 20px 0px 10px;
            height: 32px;
            z-index: 1;
        }

        .teethRow-one {
            display: grid;
            grid-template-columns: repeat(16, 1fr);
            column-gap: 0px;
            row-gap: 0px;
            top: 2px;
            z-index: 2;
            position: relative;
            margin: 0 10px;

            .tooth-sides {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                top: 55px;
                width: 98%;

                h2 {
                    margin: 0;
                    color: #000;
                    font-size: 15px;
                    font-weight: 700;

                }
            }

            .teeth-structure {
                position: relative;

                span {
                    position: absolute;
                    top: 38%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                    color: #000;
                    font-size: 12px;
                    font-weight: 700;
                }

                svg {
                    cursor: pointer;
                }
            }

            .active-teeth {
                span {
                    color: #fff;
                }

                svg {
                    path {
                        fill: #00C6E9;
                    }
                }
            }
            .teeth-structure-ias{
                .active-teeth {
    
                    svg {
                        path {
                            fill: #539ad1;
                        }
                    }
                }
            }
            .teeth-structure-aqua{
                .active-teeth {
    
                    svg {
                        path {
                            fill: #00bff2;
                        }
                    }
                }
            }
            .teeth-structure-brushbar{
                .active-teeth {
    
                    svg {
                        path {
                            fill: #0c77fa;
                        }
                    }
                }
            }
        }
    }

    .addTooth-btn {
        border-radius: 27px;
        // border: 1px solid #77888B;
        // background: #FFF;
        // width: 159px;
        // height: 38px;
        padding: 10px 20px;
        // color: #000;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // text-align: center;
        font-size: 15px;
        // font-weight: 500;
        margin-top: 13px;
        margin-left: auto;
    }
}

.ant-dropdown-menu-item {
    &:hover {
        background: #fff !important;
    }
}

.statusForm {
    width: 249px;

    .ant-select {
        border-radius: 10px;
    }

    .ant-form-item {
        margin-bottom: 10px !important;
    }

    .ant-input-disabled {
        border-radius: 6px;
        background: #00C6E9 !important;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        width: 72px;
        text-align: center;
        display: block;
        margin-left: auto;
    }

    .tootName {
        border-radius: 6px;
        // background: #00C6E9 !important;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        width: 72px;
        text-align: center;
        padding: 3px;
        margin: 0;
    }

    .statusForm-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        .save-btn {
            border-radius: 27px;
            // background: #00C6E9;
            width: 95px;
        }

        .cancel-btn {
            border-radius: 27px;
            border: 1px solid #BCBCBC;
            width: 95px;
        }
    }
}

.teethStatus-container {
    padding: 10px 20px 0px 20px;

    h1 {
        text-align: left;
        margin: 0px 0px 0px 0px;
        color: #000;
        font-size: 15px;
        font-weight: 700;
    }

    .add-tooth-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;

        p {
            color: #818181;
            font-size: 16px;
            font-weight: 400;

        }
    }
    .smile-align-no-chart-svg{
        #Vector_2{
            fill:#DE8754 !important;
        }
    }
    .ias-no-chart-svg{
        #Vector_2{
            fill:#539ad1 !important;
        }
    }
    .aqua-no-chart-svg{
        #Vector_2{
            fill:#00bff2 !important;
        }
    }
    .brushbar-no-chart-svg{
        #Vector_2{
            fill:#0c77fa !important;
        }
    }

    .status-editForm {
        .delete-btn {
            border: none !important;
            box-shadow: none !important;
            background: none;

            svg {
                path {
                    fill: rgb(0, 0, 0) !important;
                }
            }

            &:hover,
            &:active {
                svg {
                    path {
                        fill: red !important;
                    }
                }
            }
        }

        .ant-col {
            min-height: auto !important;
        }

        .ant-form-item {
            margin: 0px;
            width: 100%;
        }

        // .ant-input {
        //     height: 56px;
        // }

        // .ant-input-number {
        //     height: 56px;
        //     width: 100%;
        // }

        .ant-input-number-input-wrap {
            display: flex;
            height: 100%;
        }

        .toothName {
            border-radius: 6px;
            // background: #00C6E9 !important;
            color: #FFF;
            font-size: 16px;
            font-weight: 700;
            width: 100%;
            text-align: center;
            padding: 3px;
            margin: 0;
        }
        .toothNum-input-select{
            color:white;
            .ant-select-selector{
                background: #00C6E9 !important;
            }
            .ant-select-selection-item {
                color: #FFF;
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                text-align: center;
            }
            .ant-select-arrow{
                color: white;
            }
        }
        .toothNum-input-select-smilelign {
            color: white;

            .ant-select-selector {
                background: #DE8754 !important;
                .ant-select-selection-placeholder{
                    color: #FFF !important;
                }
            
            }

            .ant-select-selection-item {
                color: #FFF;
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                text-align: center;
            }
            .ant-select-arrow{
                color: white;
            }

        }
        .toothNum-input-select-ias {
            color: white;

            .ant-select-selector {
                background: #539ad1 !important;
                .ant-select-selection-placeholder{
                    color: #FFF !important;
                }
            
            }

            .ant-select-selection-item {
                color: #FFF;
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                text-align: center;
            }
            .ant-select-arrow{
                color: white;
            }

        }
        .toothNum-input-select-aqua {
            color: white;

            .ant-select-selector {
                background: #00bff2 !important;
                .ant-select-selection-placeholder{
                    color: #FFF !important;
                }
            
            }

            .ant-select-selection-item {
                color: #FFF;
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                text-align: center;
            }
            .ant-select-arrow{
                color: white;
            }

        }

        .toothNum-input-select-brushbar {
            color: white;

            .ant-select-selector {
                background: #0c77fa !important;
                .ant-select-selection-placeholder{
                    color: #FFF !important;
                }
            
            }

            .ant-select-selection-item {
                color: #FFF;
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                text-align: center;
            }
            .ant-select-arrow{
                color: white;
            }

        }

        .toothNum-input {
            border-radius: 6px;
            background: #00C6E9 !important;
            font-size: 16px;
            visibility: hidden;
            text-align: center;
            width: 100%;
            height: 35px;

            .ant-select-selection-item {
                color: #FFF !important;
                font-weight: 700;
            }

            svg {
                path {
                    fill: #FFF !important;
                }
            }

            input {
                text-align: center;
            }
        }


        .ant-select {
            width: 100%;
            text-align: left;
        }



        .addOn-btn {
            display: flex;
            align-items: center;
            border: none;
            color: #828282;
            font-weight: 300;
            font-family: Poppins;
            box-shadow: none;
            margin-left: auto;

        }
    }
}

.custom-placeholder .ant-select-selection-placeholder {
    color: rgb(255, 252, 252); /* Change the placeholder color */
  }

// @media only screen and (min-width: 1000px) and (max-width: 1500px) {
//     .teethChart-container {
//         .teethRow-container {
//             .teethRow-one {
//                 svg {
//                     width: 38px;
//                 }
//             }
//         }
//     }
// }

// @media only screen and (min-width: 1500px) and (max-width: 1600px) {
//     .teethChart-container {
//         .teethRow-container {
//             .teethRow-one {
//                 svg {
//                     width: 39px;
//                 }
//             }
//         }
//     }
// }

// @media only screen and (min-width: 1601px) and (max-width: 1700px) {
//     .teethChart-container {
//         .teethRow-container {
//             .teethRow-one {
//                 svg {
//                     width: 43px;
//                 }
//             }
//         }
//     }
// }

// @media only screen and (min-width: 1701px) and (max-width: 1800px) {
//     .teethChart-container {
//         .teethRow-container {
//             .teethRow-one {
//                 svg {
//                     width: 46px;
//                 }
//             }
//         }
//     }
// }

// @media only screen and (min-width: 1801px) and(max-width: 1900px) {
//     .teethChart-container {
//         .teethRow-container {
//             .teethRow-one {
//                 svg {
//                     width: 50px;
//                 }
//             }
//         }
//     }
// }

// @media only screen and (min-width: 1901px) {
//     .teethChart-container {
//         .teethRow-container {
//             .teethRow-one {
//                 svg {
//                     width: 50px;
//                 }
//             }
//         }
//     }
// }
