.partnersTableContainer {
    margin-top: 1rem;
}

// :global(.ant-radio-group) {
//     background: red !important;
// }

.statusRadio {
    font-weight: 600;
}

.headerRow {
    padding-left: 20px;
}



.partnersContainer {
    .partner-header {
        .ant-input-affix-wrapper {
            padding: 8px 11px !important;
        }
    }

    .searchInput {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .titleRow {
        margin-left: 1rem;
    }
 

}

.userName {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px 0px;

    p {
        margin: 0;
    }

    .userIcon {
        font-size: 17px;
    }
}

.sampleContainer {
    ul {
        padding-left: 20px;
    }

    svg {
        width: 100%;
    }
    .fileHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FAFAFA;
        padding: 5px 15px;
        h2{
            margin: 0px;
            color: #101623;
            font-size: 14px;
            font-weight: 700;
        }
        .downloadBtn{
             border: none;
             display: flex;
             align-items: center;
             font-weight: bold;
        }
    }
    .invitesImage{
        width: 100%;
    }
}

.invitesContainer {
    max-height: 600px;
    overflow: scroll;
    overflow-x: hidden;
    .invitesHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .invites {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .selectAll {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 5px;
    }

    .fileTags {
        display: flex;
        align-items: center;
        gap: 20px;
        .fileTag {
            background: #F8F8F8;
            padding: 12px 25px;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-weight: 700;

        }

        .fileTagError {
            color: #FF0000;
        }

        .fileTagSuccess {}
    }
}