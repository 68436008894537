.editor-container {
  width: 100%;
  height: 100%;

  :global(.ql-editor) {
    font-weight: 500;
    max-height: 120px;
    overflow-y: auto;
    min-height: 100px;
    &::before {
      font-style: normal !important;
    }
  }

  :global(.ql-toolbar) {
    text-align: left;
  }

  :global(.ql-mention-list-container) {
    width: 208px;
    z-index: 100;
    background-color: white;
  }
  :global(.ql-mention-list-item) {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }

  :global(.ql-mention-list-item.selected) {
    background: #e6f7ff !important;
    color: black;
  }
  :global(.mention){
    // background-color: black!important;
    // color: #fff;
    border-radius: 4px;
  }
}
