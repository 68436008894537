.commentModal {
  right: -32rem;
  top: 13rem !important;
}

.receiverComment {
  min-width: 60%;
  max-width: 14rem;
  float: left;
  position: relative;
  img{
    width: 100%;
    height: auto;
  }
  h2{
    font-size: 14px;
  }
}

.receiverHistory {
  background-color: #e9e9e9;
  border-radius: 10px;
  padding: 10px;
  img{
    width: 100%;
    height: auto;
  }
}

.senderComment {
  float: right;
  min-width: 60%;
  max-width: 14rem;
  position: relative;
  img{
    width: 100%;
    height: auto;
  }
}

.senderHistory {
  background: #f6f6f6 !important;
  border-radius: 10px;
  padding: 10px;
  img{
    width: 100%;
    height: auto;
  }
}
