.graph-title {
  padding: 24px 0px;
  :global(.ant-card-head) {
    border-bottom: none;
  }
}

// .tabSwitcher {
//   :global(.ant-radio-button-wrapper) {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     min-width: 180px !important;
//     text-align: center !important;
//     text-align: center;
//     height: 40px;
//   }

//   .patientDetailTopRowSwitcher {
//     display: flex;
//     justify-content: flex-end;

//     .ant-radio-group {
//       display: flex;
//     }

//     .ant-radio-button-wrapper {
 

//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }
// }
.phases-info-container {
  h1{
font-size: 24px;
font-weight: 700;
margin: 0px 0px 15px 0px;
text-align: left;

  }
  h2 {
    color: #464255;
    font-size: 44px;
    font-weight: 700;
    margin: 0px;
    text-align: left;
    line-height: 45px;

  }

  p {
    font-size: 16px;
    font-weight: 700;


  }
}