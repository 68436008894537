.single-col-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.multiple-cols-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.divider-custom {
  color: lightgray;
  height: auto;
  width: 2px;
}
.border-right{
  border-right: 2px solid lightgray;
  height: 100%;
  @media screen and (max-width: 1000px) {
    border-right: none !important;
  }
}




.border-top{
  border-top: 2px solid lightgray;
  width: 95%;
  height: 2px;
  padding-right: 5px;
  margin: 5px 0px;
}
