.prescription-header {
    margin: 20px 0 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background: #fff;
    border-radius: 8px;


    h1 {
        color: #000;
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        font-weight: 700;
        margin: 0px;
    }

    .version-date {
        color: #77888B;
        font-family: "Mulish", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
}

.prescription-form-container {
    .formRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }

    .prescription-card {
        border-radius: 13px;
        border: 1px dashed #D7D6D6;
        background: #FAFAFA;
        padding-left: 10px;
        margin-bottom: 17px;
        .num-btn{
            border-radius: 50%;
            text-align: center;
            position: absolute;
            left: -15px;
            top: 10px;
            color: #fff;
            padding: 0px 7px;
        }

        .ant-card-body {
            padding-bottom: 20px !important;
        }

        .border-right {
            border-right: 2px solid rgb(233, 233, 233);
        }

        .paddingLeft {
            padding-left: 20px;
        }

        h1 {
            color: #222;
            font-family: "Mulish", sans-serif;
            font-size: 17px;
            font-weight: 800;
            text-align: left;
        }

        .caption {
            font-family: "Mulish", sans-serif;
            font-weight: 400;
            color: #798c96;
            font-size: 13px;
            text-align: left;
            margin-bottom: 0px;
        }

        .content {
            font-family: "Mulish", sans-serif;
            font-weight: 700;
            color: #222222;
            text-align: left;
            word-break: break-all;
            // white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 0px;
            // text-transform: capitalize;
        }

        p {
            color: #000;
            font-family: "Mulish", sans-serif;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            margin-bottom: 0px;
        }

        .toothName {
            border-radius: 6px;
            color: #FFF;
            text-align: center;
            font-family: "Mulish", sans-serif;
            font-size: 16px;
             padding: 1px 10px;
            font-weight: 700;
            width: fit-content;

        }
    }
}



