.over-due-icon {
  :nth-child(4) {
    fill: red;
  }
  :nth-child(5) {
    fill: red;
  }
}

.over-due__container {
  cursor: pointer;

  border-radius: 4px;
  margin: 0 -1px;
}
.is-overdue {
  background: #ffd9d9;
}

.card-element {
  margin-bottom: 1rem;

  :global(.ant-card-body) {
    padding-left: 0;
    padding-right: 0;
  }
}

.card-padding {
  padding-left: 24px;
  padding-right: 24px;
}
