.assignee-wrapper {
  :global(.ant-select-selector) {
    border-radius: 20px;
  }
}
.assignee-ValueWrapper{
  :global(.ant-select-selector) {
    background: #F3F3F3 !important;
  }
 } 
