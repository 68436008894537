.label {
  color: #77888b;
  // font-size: 12px;
}

.value {
    text-transform: capitalize;
    color: rgba(34, 34, 34, 0.72)!important;
    margin: 0!important;
    font-size: 14px !important;
}


.bold-value {
  text-transform: capitalize;
  color: rgba(34, 34, 34, 0.72)!important;
  margin: 0!important;
  font-size: 14px !important;
  font-weight: 700 !important;
}
