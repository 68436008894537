.uploadImage {
  .ant-upload.ant-upload-select-picture-card {
    min-width: 210px;
    min-height: 150px;
    object-fit: contain;
    @media only screen and (min-width: 1200px) and (max-width: 1900px) {
      min-width: 210px;
      min-height: 150px;
      object-fit: contain;
    }
  }
}
div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}


.clinical-images-wrapper {
  position: relative;
  height: 86px;
  width: 86px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  .images-body {
    img {
      width: 100%;
      height: 100%;
    }
  }
}