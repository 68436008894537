.comment-editor {
  background: #fff;
  border-radius: 5px;
  padding: 0px 10px;
  align-items: flex-end;
  word-break: break-all;
  display: grid;
  display: grid;
  grid-template-columns: 1fr 10fr 2fr;

  > *:first-child {
    align-self: flex-start;
    margin-top: 7px;
  }

  :global(.ql-toolbar) {
    border: 1px solid #fff;
  }

  :global(.ql-container.ql-snow) {
    border: 1px solid #fff;
  }

  :global(.ql-editor) {
    min-height: 44px !important;
    max-height: 100px !important;
  }
}

.send-icons {
  display: flex;
  align-items: center;
  margin-top: 10px;
  align-self: flex-start;
  justify-self: flex-end;
}
