.scan-history-container {
    .scan-name{
        color: #8F8F8F;
        font-family: "Mulish", sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
        margin: 10px 0px !important;
    }
    .scan-file-display {
        position: relative;
        width: 100%;
        height: 134px;
        border-radius: 6px;
        border: 1px solid #E1E1E1;
        background: #FAFAFA;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .scan-file-icon{
            svg{
                width: 40px;
                height: 40px;
                margin-top: -20px;
            }
      
        }
  
        .fileName {
            color: #404040;
            font-size: 12px;
            font-weight: 400;
            margin: 0px;
            font-size: 13px;
            width: 100%;
            display: block;
            text-wrap: nowrap;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 125px;
            position: absolute;
           bottom: 10px;
           left: 10px;
        }
        .fileMask {
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
       
            opacity: 0;
            transition: opacity 0.3s;
            // width: 131px;
            // height: 131px;
            border-radius: 8px;

            span {
                padding-left: 3px;
            }
            .downIcon{
            font-size: 20px;
            margin-right: 1rem;
            cursor: pointer;
            }
            .viewIcon{
                font-size: 20px;
                margin-right: 1rem;
                cursor: pointer;
            }
        }

        &:hover {
            .fileMask {
                opacity: 1;
            }
        }
    }
}