.chat-container {
  background: #fafafa;
  padding: 10px;
  border-radius: 6px;
}

.chat-btn {
  bottom: 0px;
  right: 48px;
  border-radius: 8px;
  bottom: 0px;
  width: 150px;
  height: 47px;
  :global(.ant-float-btn-icon) {
    width: 30px !important;
  }

  :global(.ant-badge-count) {
    top: 3px !important;
    inset-inline-end: 47px !important;
  }
  :global(.ant-float-btn-content) {
    padding: 3px 12px !important;
  }

  :global(.ant-float-btn-body) {
    border-radius: 8px;
  }
}
